import { NewsFeed } from '../model/NewsFeed'
import { useQueryStore } from '~/store/query'
import { NewsResult } from '~/model/NewsResult'

export async function getNews(page: number = 1) {
  const queryStore = useQueryStore()

  try {
    const { data = [], refresh } = await useFetch(
      `/api/users/${queryStore?.userInfo?.userkey}/news?page=${page}`,
    )

    !data?.value && (await refresh())

    if (data?.value) {
      const news: Array<NewsResult> = []
      for (const index in data.value.data) {
        const item = data.value.data[index]
        news.push(
          new NewsResult(
            item.publication_date.toString(),
            item.image_url,
            item.feed.logo,
            item.feed.name,
            item.title,
            null,
            item.url,
            item.description,
            item.feed.charged,
          ),
        )
      }

      return news
    }
    return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function getFeeds() {
  const queryStore = useQueryStore()

  try {
    const { data = [], refresh } = await useFetch(
      `/api/users/${queryStore?.userInfo?.userkey}/news-feeds`,
    )

    !data?.value && (await refresh())

    if (data?.value) {
      const feeds: Array<NewsFeed> = []
      for (const index in data.value.feeds) {
        const item = data.value.feeds[index]
        feeds.push(
          new NewsFeed(
            item.id,
            item.label,
            item.description,
            item.subscribed,
            item.charged,
            item.isDefault,
            item.image_url ?? null,
          ),
        )
      }

      return feeds
    }
    return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function updateSubscribedFeeds(feeds: array): Promise<boolean> {
  const queryStore = useQueryStore()

  try {
    const { data = [], refresh } = await useFetch(
      `/api/users/${queryStore?.userInfo?.userkey}/news-feeds`,
      {
        method: 'POST',
        body: JSON.stringify({ feeds }),
      },
    )

    !data?.value && (await refresh())

    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
