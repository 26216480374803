import { defineStore } from 'pinia'
import { ImageSearchResponse } from '~/model/ImageSearchResponse'

export const useImageStore = defineStore('image', {
  state: () => ({
    imageResponse: null as ImageSearchResponse | null,
    imageToPreview: null,
    previousImageIndex: null,
    nextImageIndex: null,
  }),
  actions: {
    appendImages(value) {
      this.imageResponse.nextOffset = value.nextOffset
      this.imageResponse.images = this.imageResponse.images.concat(value.images)
    },
    setImageResponse(value) {
      this.imageResponse = value
    },
    setImageToPreview(value) {
      this.imageToPreview = value.image
      if (value.index === null) {
        this.previousImageIndex = null
        this.nextImageIndex = null
      }

      if (value.index !== null) {
        this.previousImageIndex = value.index - 1 >= 0 ? value.index - 1 : null
        this.nextImageIndex =
          value.index + 1 >= this.imageResponse.images.length
            ? null
            : value.index + 1
      }
    },
  },
})
