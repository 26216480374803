export default defineNuxtPlugin(({ vueApp, $device }) => {
  const deviceMixin = {
    data() {
      return {
        device: $device,
      }
    },
  }

  vueApp.mixin(deviceMixin)
})
