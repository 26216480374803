import { filename } from 'pathe/utils'
import { useMainStore } from '~/store/index'

export class AntibotClient {
  monolithDomain: string
  wordpressDomain: string
  rabbitMqDsn: string

  constructor() {
    const config = useRuntimeConfig()

    this.monolithDomain = config.monolithDomain
    this.wordpressDomain = config.public.wordpressDomain
    this.rabbitMqDsn = config.rabbitMqDsn
  }

  async checkIp(
    userkey: string,
    ip: string,
    query: string,
    page: number,
    route: {
      query: {
        externalsource: string
        plugin: string
        t: string
      }
    },
    $device: { isDesktop: Boolean; isTablet: Boolean },
    baui: string,
  ) {
    const isBlocked = await this.isIpBlocked(ip)

    await $fetch('/api/message/user-search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userkey,
        ip,
        query,
        page,
        baui,
      }),
    })

    if (isBlocked) {
      if (
        !route.query.externalsource &&
        route.query.plugin !== 'lilose' &&
        route.query.t !== 'homemobile'
      ) {
        const glob = import.meta.glob('@/assets/images/antibot-bg-*.png', {
          eager: true,
        })
        const antibotBackgroundImages = Object.fromEntries(
          Object.entries(glob).map(([key, value]) => [
            filename(key),
            value.default,
          ]),
        )
        const { isDev } = useMainStore()

        return {
          domain: isDev
            ? 'localhost'
            : this.monolithDomain.split('https://ws.')[1],
          ipBlocked: isBlocked,
          displayHome: false,
          showModalAntibot: true,
          showModalResult: false,
          antibotKey: 0,
          captchaResult: false,
          antibotBackgroundImage: $device.isDesktop
            ? antibotBackgroundImages['antibot-bg-desktop']
            : $device.isTablet
              ? antibotBackgroundImages['antibot-bg-tablet']
              : antibotBackgroundImages['antibot-bg-mobile'],
        }
      }
    }
  }

  async isIpBlocked(ip: string): Promise<boolean> {
    const trustCookieValue = useCookie('TRUST')?.value

    if (trustCookieValue) {
      return false
    }

    return (await $fetch(`${this.monolithDomain}/search/ip/${ip}`)
      .then(function (res: unknown) {
        const response = res as { data: any }
        return response?.status === 'blacklisted'
      })
      .catch(function (error) {
        // Todo : alerting / raise a sentry error
        console.log('error in isIpBlocked', error)
        return false
      })) as unknown as Promise<boolean>
  }

  async whitelistIp(): Promise<void> {
    const mainStore = useMainStore()

    await $fetch(`/api/antibot/captcha-solved?ip=${mainStore.connectingIp}`, {
      method: 'GET',
    })
  }

  async getCountryCaptcha(): Promise<string> {
    return await $fetch(
      `${this.wordpressDomain}/wp-content/themes/jarvis_wp/colibri/search/api/?service=lilocapcha&action=getrandomcountry`,
    )
      .then(function (res: unknown) {
        const response = res as { response: { country: string } }
        return response.response.country
      })
      .catch(function (error) {
        // Todo : alerting / raise a sentry error
        console.log('error in getCountryCaptcha', error)
        return 'TAIWAN'
      })
  }
}
