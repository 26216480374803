<template>
  <div class="is-size-7-touch is-size-6 is-one-line is-inline-flex">
    <a
      class="has-text-grey-darker is-one-line"
      :href="url"
      :data-bingads-suffix="isAd ? adPingSuffix : null"
    >
      <span class="has-text-weight-medium">{{ formattedUrl.hostname }}</span>
      <span
        v-for="(item, index) in formattedUrl.path"
        :key="'split-path-' + index"
        class="has-text-grey"
      >
        › {{ decodeURIComponent(item) }}
      </span>
    </a>
    <span v-if="isAd" class="level-item ml-2 end is-size-7-mobile">
      <VTooltip>
        <a class="has-text-grey">{{ translate('text_result.ad') }}</a>
        <template #popper>
          {{ translate('text_result.ad_why') }}
        </template>
      </VTooltip>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BreadCrumb',

  props: {
    url: {
      type: String,
      default() {
        return ''
      },
    },
    displayUrl: {
      type: String,
      default() {
        return ''
      },
    },
    isAd: {
      type: Boolean,
      default() {
        return true
      },
    },
    adPingSuffix: {
      type: String,
      default() {
        return ''
      },
    },
  },

  computed: {
    formattedUrl(): Object {
      let url: URL | null = null

      try {
        url = new URL(
          this.isAd ? `https://${this.displayUrl}` : this.displayUrl,
        )
      } catch (e) {
        try {
          url = new URL(this.url)
        } catch (e) {
          return {
            hostname: this.displayUrl,
            path: [],
          }
        }
      }

      return {
        hostname: this.isAd ? url.hostname : `${url.protocol}//${url.hostname}`,
        path: url.pathname === '/' ? null : url.pathname.split('/').slice(1),
      }
    },
  },
})
</script>

<style scoped lang="scss">
.is-inline-flex {
  width: calc(100% - 8px);
  .end {
    &:before {
      content: '·';
      left: -0.25rem;
      position: relative;
    }
    font-size: 0.8rem;
  }
}
</style>
