import type { Pinia } from 'pinia'
import enJson from '../i18n/en.json'
import frJson from '../i18n/fr.json'
import { useMainStore } from '~/store/index'

export default defineNuxtPlugin((nuxtApp) => {
  const translate = (key: string, locale?: string) => {
    if (locale) {
      return locale === 'en' ? enJson[key] : frJson[key]
    }

    const mainStore = useMainStore(nuxtApp.$pinia as Pinia)
    return mainStore.locale === 'en' ? enJson[key] : frJson[key]
  }

  const translateMixin = {
    methods: {
      translate(key: string, locale?: string) {
        if (locale) {
          return locale === 'en' ? enJson[key] : frJson[key]
        }

        const mainStore = useMainStore(nuxtApp.$pinia as Pinia)
        return mainStore.locale === 'en' ? enJson[key] : frJson[key]
      },
    },
  }

  nuxtApp.vueApp.mixin(translateMixin)

  // Inject the function globally as $translate
  nuxtApp.provide('translate', translate)
})
