<template>
  <div class="slides">
    <div
      v-for="(slide, i) in slides"
      :key="slide"
      class="slide"
      :style="{ 'animation-delay': i * 4 + 's' }"
    >
      <svg
        role="img"
        width="55px"
        viewBox="0 0 1000 1000"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <pattern
          :id="'img' + i"
          patternUnits="userSpaceOnUse"
          width="1000"
          height="1000"
        >
          <image :href="slide" x="0" y="0" width="1000" height="1000"></image>
        </pattern>
        <g
          id="SITE-LILO"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Desktop" :fill="'url(#img' + i + ')'">
            <path
              id="Combined-Shape"
              d="M746.883904,49.2335914 C645.294043,-11.0849364 507.226986,0.986282373 398.577376,0.986282373 C289.927767,0.986282373 207.304418,91.7708633 175.542601,117.372115 C143.780784,142.973367 21.6541852,327.493028 13.1229374,405.644465 C4.59168956,483.795901 24.5282316,607.13145 24.5282316,649.916032 C24.5282316,692.700614 48.6802506,709.041838 80.4405003,804.71783 C112.20075,900.393821 238.472307,946.465431 273.263312,960.627825 C308.054318,974.79022 492.259692,1037.63193 653.902078,967.270752 C815.544464,896.909572 905.975064,775.719982 937.177429,719.705896 C968.379794,663.69181 979.797625,597.799422 988.07187,519.740334 C996.346115,441.681247 963.114366,308.985495 931.148827,240.296003 C899.183288,171.606511 848.473764,109.552119 746.883904,49.2335914 Z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
const slides = [
  'diaporama/diaporama-coeur-de-foret-100x100.jpg',
  'diaporama/diaporama-arutam-planete-200x200.jpg',
  'diaporama/diaporama-enfant-du-mekong-100x100.jpg',
  'diaporama/diaporama-rouge-gorge-Bernard-Deceuninck-LPO-100x100.jpg',
  'diaporama/diaporama-tortue-sur-sable-100x100.jpg',
]
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';
@import '~/assets/scss/utils/variables.scss';

.slides {
  width: 55px;
  height: 55px;
  margin: 0 auto;
}

.slide {
  position: absolute;
  animation: fade 20s infinite linear;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
