// @ts-nocheck
import { defineComponent, PropType } from 'vue'
import { parse, Duration } from 'tinyduration'
import SectionTitle from '../SectionTitle.vue'
import { VideoResult } from '~/model/VideoResult'

export default defineComponent({
  components: {
    SectionTitle,
  },

  props: {
    videoData: {
      type: Object as PropType<VideoResult>,
      default() {
        return {}
      },
    },
  },

  computed: {
    isEmbed(): Boolean {
      return (
        this.videoData.embedHtml !== null &&
        this.videoData.allowHttpsEmbed !== null &&
        this.videoData.allowHttpsEmbed
      )
    },
    readableDuration(): string | null {
      if (this.videoData.duration) {
        const durationObj: Duration = parse(this.videoData.duration)

        let seconds: string | number = durationObj.seconds?.toString() ?? '00'
        if (durationObj.seconds && durationObj.seconds < 10) {
          seconds = '0' + seconds
        }

        return `${durationObj.minutes ?? 0}:${seconds}`
      }

      return null
    },
  },
})
