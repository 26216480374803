export class WikipediaResponse {
  description: string
  extract: string
  pageUrl: string
  thumbnailUrl: string | null
  title: string

  constructor(
    description: string,
    extract: string,
    pageUrl: string,
    thumbnailUrl: string | null,
    title: string,
  ) {
    this.description = description
    this.extract = extract
    this.pageUrl = pageUrl
    this.thumbnailUrl = thumbnailUrl
    this.title = title
  }
}
