import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    locale: 'fr',
    connectingIp: null,
    userAgent: null,
    isDev: process.env.NODE_ENV === 'development',
    assetsPath: 'https://static.lilo.org/min/?g=7282463',
    clearLocalStorage: false,
    displayTribeWelcome: false,
    userApiError: false,
  }),
  actions: {
    updateLocale(value: string) {
      this.locale = value === 'en' ? 'en' : 'fr'
    },
  },
})
