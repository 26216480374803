<template>
  <div
    :class="{
      'mb-5': device.isDesktop,
      'mb-1 p-1 has-background-white': !device.isDesktop,
    }"
  >
    <div
      v-if="placesData.length > 2 && showGradient"
      class="gradient-container"
    />
    <div
      class="lilo-places"
      :class="{
        'has-max-height': showGradient,
      }"
    >
      <PlaceListItem
        v-for="(item, index) in placesData"
        :key="'place-item-' + index"
        :place="item"
      />
    </div>
    <div
      v-if="placesData.length > 2 && showGradient"
      class="is-inline-flex is-justify-content-center p-3 mt-2"
    >
      <button class="lilo-button" @click="hideGradient">
        {{ translate('places.more') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import PlaceListItem from './PlaceListItem.vue'
import { PlaceResult } from '~/model/PlaceResult'

export default defineComponent({
  name: 'PlacesResults',

  components: {
    PlaceListItem,
  },

  props: {
    placesData: {
      type: Array as PropType<Array<PlaceResult>>,
      default() {
        return []
      },
    },
  },

  data: function () {
    return {
      showGradient: true,
    }
  },

  methods: {
    hideGradient(event: Event) {
      event.preventDefault()
      this.showGradient = false
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';

.lilo-places {
  overflow-y: clip;
}

.has-max-height {
  max-height: 23.5rem;
}

.horizontal-line {
  content: '';
  display: block;
  width: 10rem;
  height: 2px;
  background: #daecff;
  position: absolute;
  top: 50%;
  z-index: -2;
}

.is-inline-flex {
  width: 100%;
}

.lilo-button:before {
  @extend .horizontal-line;
  left: -8.1rem;
}

.lilo-button:after {
  @extend .horizontal-line;
  right: -8.1rem;
}

@media screen and (max-width: $large - 1) {
  .horizontal-line {
    width: 6rem;
  }

  .lilo-button:before {
    left: -6.1rem;
  }

  .lilo-button:after {
    right: -6.1rem;
  }
}

.gradient-container {
  min-height: 23.5rem;
  min-width: 31rem;
  position: absolute;
  box-shadow: inset 0px -5rem 5rem 0px rgb(255 255 255 / 75%);
}
</style>
