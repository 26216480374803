<template>
  <NuxtLayout>
    <div class="container">
      <div class="columns">
        <div class="column is-half-desktop is-full-touch p-6">
          <a
            id="logo"
            href="https://search.lilo.org"
            class="column p-0 is-1 mb-6"
          >
            <img
              src="@/assets/images/logo-baseline.png"
              alt="Link to search engine home"
            />
          </a>
          
          <div v-if="error.statusCode === 404">
            <h4 class="is-size-5 has-text-primary has-text-weight-bold">
              {{ translate('error.404.title') }}
            </h4>
            <p
              class="is-font-family-secondary mt-2 has-text-justified"
              v-html="translate('error.404.description')"
            ></p>
            <h4 class="is-size-5 has-text-primary has-text-weight-bold mt-5">
              {{ translate('error.404.title', 'en') }}
            </h4>
            <p
              class="is-font-family-secondary mt-2 has-text-justified"
              v-html="translate('error.404.description', 'en')"
            ></p>
          </div>
          <div v-else>
            <h4 class="is-size-5 has-text-primary has-text-weight-bold">
              {{ translate('error.500.title') }}
            </h4>
            <p
              class="is-font-family-secondary mt-2"
              v-html="translate('error.500.description')"
            ></p>
            <h4 class="is-size-5 has-text-primary has-text-weight-bold mt-5">
              {{ translate('error.500.title', 'en') }}
            </h4>
            <p
              class="is-font-family-secondary mt-2"
              v-html="translate('error.500.description', 'en')"
            ></p>
          </div>
          <div id="actions" class="mt-6">
            <a class="has-text-weight-semibold" href="https://search.lilo.org">
              {{ translate('error.action.search_again') }} /
              {{ translate('error.action.search_again', 'en') }}
            </a>
            <a
              class="has-text-weight-semibold"
              href="https://www.lilo.org/projets"
            >
              {{ translate('error.action.projects') }} /
              {{ translate('error.action.projects', 'en') }}
            </a>
            <a class="has-text-weight-semibold" href="https://support.lilo.org">
              {{ translate('error.action.support') }} /
              {{ translate('error.action.support', 'en') }}
            </a>
          </div>
        </div>
        <div class="column is-half is-hidden-touch">
          <img
            class="astronaut"
            src="@/assets/images/error-astronaut.svg"
            alt="error image"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const error = useError()
const config = useRuntimeConfig()
if (!config.sentry) {
  console.log('error page', error)
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';
@import '~/assets/scss/layouts/error.scss';
</style>
