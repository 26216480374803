<template>
  <footer class="mt-6 mb-0">
    <div class="has-background-light-blue">
      <div class="p-4">
        <div class="level">
          <div class="level-left columns is-multiline is-mobile">
            <div class="column is-narrow">
              <lilo-dropdown aria-role="list" position="is-top-right">
                <template #trigger="{}">
                  <a class="has-text-grey-dark icon-text align-items-center">
                    Lilo
                    <Icon name="mdi:chevron-up" />
                  </a>
                </template>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/qui-est-lilo/"
                >
                  <span class="has-text-weight-semibold">Qui est Lilo</span>
                </lilo-dropdown-item>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/espace-presse/"
                >
                  <span class="has-text-weight-semibold">Espace Presse</span>
                </lilo-dropdown-item>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/recrutement/"
                >
                  <span class="has-text-weight-semibold">Recrutement</span>
                </lilo-dropdown-item>
              </lilo-dropdown>
            </div>
            <div class="column is-narrow">
              <a
                href="https://www.lilo.org/votre-vie-privee-avec-lilo"
                class="has-text-grey-dark"
                >Vie privée</a
              >
            </div>
            <div class="column is-narrow">
              <lilo-dropdown aria-role="list" class="" position="is-top-right">
                <template #trigger="{}">
                  <a class="has-text-grey-dark icon-text align-items-center">
                    Légal
                    <Icon name="mdi:chevron-up" />
                  </a>
                </template>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/mentions-legales"
                >
                  <span class="has-text-weight-semibold">Mentions légales</span>
                </lilo-dropdown-item>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/cgu-lilo"
                >
                  <span class="has-text-weight-semibold">CGU</span>
                </lilo-dropdown-item>
                <lilo-dropdown-item
                  class="dropdown-item"
                  aria-role="listitem"
                  href="https://www.lilo.org/charte-donnees-personnelles"
                >
                  <span class="has-text-weight-semibold"
                    >Protection des données personnelles</span
                  >
                </lilo-dropdown-item>
              </lilo-dropdown>
            </div>
            <div class="column is-narrow">
              <ClientOnly>
                <a
                  href="javascript:Didomi.preferences.show()"
                  class="has-text-grey-dark"
                  >Gestion des cookies</a
                >
              </ClientOnly>
            </div>
            <div class="column is-narrow">
              <a
                href="https://www.lilo.org/proposer-un-projet-info"
                class="has-text-grey-dark"
                >Proposer un projet à soutenir</a
              >
            </div>
            <div class="column is-narrow">
              <a
                href="https://www.lilo.org/contactez-nous"
                class="has-text-grey-dark"
                >Nous contacter</a
              >
            </div>
          </div>
          <div class="level-right columns is-mobile">
            <a>
              <figure class="column image is-32x32">
                <img
                  src="@/assets/images/icons/footer/android.svg"
                  alt="android icon"
                />
              </figure>
            </a>
            <a>
              <figure class="column image is-32x32">
                <img
                  src="@/assets/images/icons/footer/ios.svg"
                  alt="ios icon"
                />
              </figure>
            </a>
            <a>
              <figure class="column image is-32x32">
                <img
                  src="@/assets/images/icons/footer/facebook.svg"
                  alt="facebook icon"
                />
              </figure>
            </a>
            <a>
              <figure class="column image is-32x32">
                <img
                  src="@/assets/images/icons/footer/twitter.svg"
                  alt="twitter icon"
                />
              </figure>
            </a>
            <a>
              <figure class="column image is-32x32">
                <img
                  src="@/assets/images/icons/footer/instagram.svg"
                  alt="instagram icon"
                />
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'LiloFooter',
}
</script>
