<template>
  <a
    class="card is-inline-block mb-4"
    :class="{
      'column is-half py-0': device.isMobile,
      'column is-one-quarter py-0': device.isTablet,
      'is-active':
        device.isDesktop && imageResult === imageStore.imageToPreview,
    }"
    :style="{
      width: device.isDesktop
        ? imageStore.imageToPreview
          ? getImageWidth > 15
            ? getImageWidth * 1.3 + '%'
            : getImageWidth * 1.667 + '%'
          : getImageWidth + '%'
        : null,
    }"
    @click="setImageToPreview"
  >
    <div class="card-image">
      <img :src="imageResult.thumbnailUrl" :alt="imageResult.name" />
    </div>
    <div class="card-content is-font-family-secondary p-1 is-size-6">
      <div class="is-one-line has-text-grey">
        <figure
          v-if="imageResult.hostPageFavIconUrl"
          class="image is-inline-flex mr-1 is-16x16"
        >
          <img
            class="media-logo"
            :src="imageResult.hostPageFavIconUrl"
            :alt="imageResult.hostPageUrl"
          />
        </figure>
        <span class="is-size-7">{{ hostPageDisplayText }}</span>
      </div>
      <p class="is-one-line">
        {{ imageResult.name }}
      </p>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { ImageResult as ImageResultModel } from '~/model/ImageResult'

export default defineComponent({
  name: 'ImageResult',

  props: {
    imageResult: {
      type: Object as PropType<ImageResultModel>,
      default() {
        return null
      },
    },
    index: {
      type: Number,
      default() {
        return null
      },
    },
  },

  computed: {
    getImageWidth(): number {
      if (
        this.imageResult.thumbnailWidth === null ||
        this.imageResult.thumbnailHeight === null
      ) {
        return 15
      }

      const ratio =
        this.imageResult.thumbnailWidth / this.imageResult.thumbnailHeight

      if (ratio < 0.7) {
        return 12.5
      }

      if (ratio < 1) {
        return 15
      }

      if (ratio < 1.3) {
        return 17.5
      }

      if (ratio > 1.99) {
        return 25
      }

      return ratio * 10
    },
    hostPageDisplayText(): string {
      if (this.imageResult.hostPageDisplayName) {
        return this.imageResult.hostPageDisplayName
      }

      let displayText: string | null = null

      try {
        const url = new URL(this.imageResult.hostPageUrl)
        displayText = url.hostname.replace('www.', '')
      } catch (e) {
        displayText = this.imageResult.hostPageUrl
      }

      return displayText
    },
  },

  methods: {
    setImageToPreview(): void {
      this.imageStore.setImageToPreview({
        image: this.imageResult,
        index: this.index,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
  border: none !important;

  .card-image {
    height: 11rem;
    flex-grow: 1;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      vertical-align: middle;
      border-radius: 5px;
    }
  }

  .card-content {
    .media-logo {
      position: absolute;
      top: 3px;
    }
  }
}
</style>
