<template>
  <div class="column is-full lilo-border-radius p-3">
    <Skeleton height="170px" />
  </div>
</template>

<script>
import Skeleton from '../../Skeleton'
export default {
  name: 'NewsSkeleton',
  components: { Skeleton },
}
</script>

<style scoped></style>
