@
<template>
  <div class="card" @click="updateSearchQuery">
    <div class="media">
      <figure
        v-if="relatedSearch.thumbnailUrl"
        class="media-left image is-48x48"
      >
        <img :src="relatedSearch.thumbnailUrl" :alt="relatedSearch.text" />
      </figure>
      <div class="media-content is-align-self-center mr-1">
        <div class="content">
          <p class="is-size-6 has-text-grey-dark has-text-weight-semibold">
            {{ relatedSearch.displayText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { RelatedSearch as RelatedSearchModel } from '~/model/RelatedSearch'

export default defineComponent({
  name: 'RelatedSearch',

  props: {
    relatedSearch: {
      type: Object as PropType<RelatedSearchModel>,
      default() {
        return null
      },
    },
  },

  methods: {
    updateSearchQuery() {
      window.location.href = `?action=image&q=${this.relatedSearch.text}`
    },
  },
})
</script>

<style lang="scss" scoped>
.media-left {
  margin-right: 0.75rem;
}

.content {
  min-width: 90px;
}

.is-48x48 {
  img {
    height: 48px !important;
    overflow: hidden;
    object-fit: cover;
  }
}

img {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
</style>
