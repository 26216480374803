export class ImageResult {
  name: string
  thumbnailUrl: string
  hostPageUrl: string
  hostPageFavIconUrl: string | null
  hostPageDisplayName: string | null
  contentUrl: string | null
  contentSize: number | null
  width: number | null
  height: number | null
  thumbnailWidth: number | null
  thumbnailHeight: number | null

  constructor(
    name: string,
    thumbnailUrl: string,
    hostPageUrl: string,
    hostPageFavIconUrl: string | null = null,
    contentUrl: string | null = null,
    contentSize: number | null = null,
    width: number | null = null,
    height: number | null = null,
    hostPageDisplayName: string | null = null,
    thumbnailWidth: number | null = null,
    thumbnailHeight: number | null = null,
  ) {
    this.name = name
    this.thumbnailUrl = thumbnailUrl
    this.hostPageUrl = hostPageUrl
    this.hostPageFavIconUrl = hostPageFavIconUrl
    this.contentUrl = contentUrl
    this.contentSize = contentSize
    this.width = width
    this.height = height
    this.hostPageDisplayName = hostPageDisplayName
    this.thumbnailWidth = thumbnailWidth
    this.thumbnailHeight = thumbnailHeight
  }
}
