import { DeepLink } from '~/model/DeepLink'
import { TextResult as TextResultModel } from '~/model/TextResult'
import { NewsResult } from '~/model/NewsResult'
import { RelatedSearch } from '~/model/RelatedSearch'
import { WebSearchResponse } from '~/model/WebSearchResponse'
import { VideoResult } from '~/model/VideoResult'
import { ImageResult } from '~/model/ImageResult'
import { ProductAd } from '~/model/ProductAd'
import { Pagination, PaginationLink } from '~/model/Pagination'
import { PlaceResult } from '~/model/PlaceResult'
import { Computation } from '~/model/Computation'
import { ImageSearchResponse } from '~/model/ImageSearchResponse'
import { MultimediaAd } from '~/model/MultimediaAd'

function moveTemuProducts(a: ProductAd, b: ProductAd): number {
  if (
    a.sellerName.toLowerCase().includes('temu') &&
    !b.sellerName.toLowerCase().includes('temu')
  ) {
    return 1
  }

  if (
    !a.sellerName.toLowerCase().includes('temu') &&
    b.sellerName.toLowerCase().includes('temu')
  ) {
    return -1
  }

  return 0
}

export function parseWebResponse(data: any): WebSearchResponse {
  const places: Array<PlaceResult> = []
  const uniquePlaces: Array<string> = []
  if (data.places) {
    for (const index in data.places.value) {
      const result = data.places.value[index]
      let schedule: string = ''
      for (const i in result.openingHoursSpecification) {
        const timetable = result.openingHoursSpecification[i]
        if (timetable.opens && timetable.closes) {
          schedule += ` ${timetable.dayOfWeek.slice(0, 2)} ${(
            '0' + timetable.opens.hour
          ).slice(-2)}:${('0' + timetable.opens.minute).slice(-2)}-${(
            '0' + timetable.closes.hour
          ).slice(-2)}:${('0' + timetable.closes.minute).slice(-2)};`
        }
      }
      if (!uniquePlaces.includes(result.bingId)) {
        places.push(
          new PlaceResult(
            result.address?.text,
            result.description,
            result.image?.contentUrl,
            result.routablePoint?.latitude ?? result.geo?.latitude,
            result.routablePoint?.longitude ?? result.geo?.longitude,
            result.name,
            result.telephone,
            result.aggregateRating?.ratingValue,
            result.aggregateRating?.ratingCount,
            schedule,
            result.url,
          ),
        )
        uniquePlaces.push(result.bingId)
      }
    }
  }

  const pages = []
  if (data.pagination && data.pagination.paginationLinks) {
    for (const index in data.pagination.paginationLinks) {
      const result = data.pagination.paginationLinks[index]
      pages.push(new PaginationLink(result.pageNumber, result.offset))
    }
  }

  const imageResults = []
  if (data.images) {
    for (const index in data.images.value) {
      const result = data.images.value[index]

      imageResults.push(
        new ImageResult(
          result.name,
          result.thumbnailUrl,
          result.hostPageDisplayUrl,
        ),
      )
    }
  }

  const topAds = []
  const productAds = []
  let multimediaAd = null
  if (data.ads) {
    for (const index in data.ads.value) {
      const ad = data.ads.value[index]
      if (ad.position === 'Mainline' && ad._type === 'Ads/TextAd') {
        const deepLinks = []
        for (const extensionIndex in ad.extensions) {
          if (ad.extensions[extensionIndex]._type === 'Ads/SiteLinkExtension') {
            for (const deepLinkIndex in ad.extensions[extensionIndex]
              .siteLinks) {
              deepLinks.push(
                new DeepLink(
                  ad.extensions[extensionIndex].siteLinks[deepLinkIndex].text,
                  ad.extensions[extensionIndex].siteLinks[deepLinkIndex].link,
                  null,
                ),
              )
            }
          }
        }

        topAds.push(
          new TextResultModel(
            deepLinks,
            true,
            ad.title,
            ad.description,
            ad.url,
            ad.displayUrl,
            null,
            ad.urlPingSuffix,
          ),
        )
      }

      if (ad._type === 'Ads/ProductAd') {
        let rating: number | null = null
        for (const extensionIndex in ad.extensions) {
          if (
            ad.extensions[extensionIndex]._type === 'Ads/ProductRatingExtension'
          ) {
            rating = ad.extensions[extensionIndex].rating
          }
        }
        productAds.push(
          new ProductAd(
            ad.primaryImageInfo?.colorInfo?.layoutColor,
            ad.offer.urlPingSuffix,
            ad.offer.priceCurrencySymbol,
            ad.offer.itemOffered.name,
            ad.offer.price,
            ad.offer.lowPrice,
            ad.offer.seller?.name,
            ad.shippingInfo?.description,
            ad.offer.itemOffered.image.contentUrl,
            ad.offer.url,
            rating,
          ),
        )
      }

      if (ad._type === 'Ads/MultimediaAd') {
        let imageUrl = null
        let cta = null
        for (const assetIndex in ad.assets) {
          if (ad.assets[assetIndex]._type === 'Ads/ImageAsset') {
            imageUrl = ad.assets[assetIndex].images[0].contentUrl
          }
          if (ad.assets[assetIndex]._type === 'Ads/ActionLinkAsset') {
            cta = ad.assets[assetIndex].actionItems[0].text
          }
        }

        if (imageUrl && cta) {
          multimediaAd = new MultimediaAd(
            ad.urlPingSuffix,
            ad.description,
            ad.displayUrl,
            imageUrl,
            ad.url,
            ad.title,
            cta,
          )
        }
      }
    }
  }
  productAds.sort(moveTemuProducts)

  const newsResults = []
  if (data.news) {
    for (const index in data.news.value) {
      const result = data.news.value[index]

      newsResults.push(
        new NewsResult(
          result.datePublished,
          result.image?.contentUrl ?? null,
          result.provider && result.provider[0].image
            ? result.provider[0].image.thumbnail.contentUrl
            : null,
          result.provider ? result.provider[0].name : null,
          result.name,
          result.image?.thumbnail?.contentUrl,
          result.url,
          result.description,
        ),
      )
    }
  }

  const textResults = []
  if (data.webPages) {
    for (const index in data.webPages.value) {
      const result = data.webPages.value[index]
      const deepLinks = []
      for (const deepLinkIndex in result.deepLinks) {
        deepLinks.push(
          new DeepLink(
            result.deepLinks[deepLinkIndex].name,
            result.deepLinks[deepLinkIndex].url,
            result.deepLinks[deepLinkIndex].snippet,
          ),
        )
      }

      textResults.push(
        new TextResultModel(
          deepLinks,
          false,
          result.name,
          result.snippet,
          result.url,
          result.displayUrl,
          result.thumbnailUrl,
        ),
      )
    }
  }

  const relatedSearches = []
  if (data.relatedSearches) {
    for (const index in data.relatedSearches.value) {
      const relatedSearch = data.relatedSearches.value[index]
      relatedSearches.push(
        new RelatedSearch(relatedSearch.text, relatedSearch.displayText),
      )
    }
  }

  const mainlineRankingResponse = []
  if (data.rankingResponse) {
    if (data.rankingResponse.mainline?.items) {
      for (const index in data.rankingResponse.mainline.items) {
        mainlineRankingResponse.push(
          data.rankingResponse.mainline.items[index].answerType,
        )
      }
    } else if (data.rankingResponse.mainline) {
      for (const index in data.rankingResponse.mainline) {
        mainlineRankingResponse.push(
          data.rankingResponse.mainline[index].answerType,
        )
      }
    }
  }

  const videoResults = []
  if (data.videos) {
    for (const index in data.videos.value) {
      const result = data.videos.value[index]
      videoResults.push(
        new VideoResult(
          result.contentUrl,
          result.datePublished,
          result.duration,
          result.name,
          result.thumbnailUrl,
          result.publisher ? result.publisher[0].name : null,
          null,
        ),
      )
    }
  }

  return new WebSearchResponse(
    imageResults,
    topAds,
    productAds,
    relatedSearches,
    newsResults,
    textResults,
    mainlineRankingResponse,
    videoResults,
    new Pagination(
      data.pagination ? data.pagination.currentPageNumber : 1,
      pages,
    ),
    places,
    data.computation
      ? new Computation(data.computation.expression, data.computation.value)
      : null,
    data.queryContext.alterationDisplayQuery
      ? data.queryContext.alterationDisplayQuery
      : null,
    data.queryContext.alterationOverrideQuery
      ? data.queryContext.alterationOverrideQuery
      : null,
    multimediaAd,
  )
}

export function parseImageResponse(data: any): ImageSearchResponse {
  const imageResults: Array<ImageResult> = []
  if (data.value) {
    for (const index in data.value) {
      const result = data.value[index]

      imageResults[index] = new ImageResult(
        result.name,
        result.thumbnailUrl,
        result.hostPageUrl,
        result.hostPageFavIconUrl,
        result.contentUrl,
        result.contentSize,
        result.width,
        result.height,
        result.hostPageDomainFriendlyName,
        result.thumbnail.width,
        result.thumbnail.height,
      )
    }
  }

  const relatedSearches = []
  if (data.queryExpansions) {
    for (const index in data.queryExpansions) {
      const result = data.queryExpansions[index]

      relatedSearches.push(
        new RelatedSearch(
          result.text,
          result.displayText,
          result.thumbnail.thumbnailUrl,
        ),
      )
    }
  }

  return new ImageSearchResponse(
    data.nextOffset < data.totalEstimatedMatches ? data.nextOffset : null,
    imageResults,
    relatedSearches,
  )
}

function getBaseHeaders(
  query: string,
  connectingIp: string,
  userAgent: string,
  muid: string | null,
): HeadersInit {
  const config = useRuntimeConfig()

  return {
    'User-Agent': userAgent,
    Referer: `${config.domain}/?q=${encodeURIComponent(query)}`,
    'X-MSEdge-ClientIP': connectingIp,
    'X-MSEdge-ClientID': muid || '',
  }
}

export class BingClient {
  async search(
    queryStore: any,
    mainStore: any,
  ): Promise<WebSearchResponse | null> {
    const config = useRuntimeConfig()

    const searchParams: any = {
      appId: config.bingAppId,
      adTypesFilter: 'TextAds,ProductAds,MultimediaAds',
      adUnitId: queryStore.baui,
      offset:
        queryStore?.currentPageNumber > 1
          ? queryStore?.currentPageNumber * 10
          : 0,
      count: 10,
      mkt: queryStore.filterMarket,
      propertyId: config.bingPropertyId,
      q: queryStore.q,
      textDecorations: false,
      safesearch:
        queryStore?.userInfo?.search_preferences?.safesearch ?? 'Moderate',
      mainlineCount: 3,
      supportedAdExtensions:
        'SiteLinks,EnhancedSiteLinks,DynamicSiteLinks,LongAdTitle',
      supportedProductAdExtensions: 'ProductRating',
    }

    if (queryStore?.filterDate !== 'All') {
      searchParams.freshness = queryStore?.filterDate
    }

    const getSearchResponse = async ({
      retry,
    }: {
      retry: number
    }): Promise<WebSearchResponse | null> => {
      return await $fetch
        .raw('https://www.bingapis.com/api/v7/search', {
          headers: getBaseHeaders(
            queryStore?.q,
            mainStore?.connectingIp,
            mainStore?.userAgent,
            queryStore.muid,
          ),
          params: searchParams,
          timeout: 2000,
        })
        .then(function (res) {
          queryStore.setRequestGUID(res.headers?.get('bingapis-rguid'))
          queryStore.setPageLoadPingUrl(
            res._data?.instrumentation?.pageLoadPingUrl,
          )
          queryStore.setPingUrlBase(res._data?.instrumentation?.pingUrlBase)

          return parseWebResponse(res._data)
        })
        .catch(async function (error) {
          if (retry > 0) {
            if (error.code === 'ECONNRESET') {
              return await getSearchResponse({ retry: retry - 1 })
            }

            if (error.response) {
              if ([500, 502, 503].includes(error.response.status)) {
                return await getSearchResponse({ retry: retry - 1 })
              }
            }
          }

          return null
        })
    }

    if (queryStore.q.length > 800) {
      return null
    }

    return await getSearchResponse({ retry: 1 })
  }

  async searchMoreImages(
    offset: number,
    queryStore: any,
  ): Promise<ImageSearchResponse | null> {
    const searchParams: any = {
      offset,
      count: 150,
      mkt: queryStore?.filterMarket,
      q: queryStore?.q,
    }

    if (queryStore.filterDate !== 'All') {
      searchParams.freshness = queryStore.filterDate
    }

    if (queryStore.filterSize !== 'All') {
      searchParams.size = queryStore.filterSize
    }

    const getImageSearchReponse = async ({
      retry,
    }: {
      retry: number
    }): Promise<ImageSearchResponse | null> => {
      return await $fetch('https://ws.lilo.org/image/search', {
        params: searchParams,
      })
        .then(function (res: { data: any }) {
          return parseImageResponse(res)
        })
        .catch(async function (error) {
          if (retry > 0) {
            if (error.code === 'ECONNRESET') {
              return await getImageSearchReponse({ retry: retry - 1 })
            }

            if (error.response) {
              if ([500, 502, 503].includes(error.response.status)) {
                return await getImageSearchReponse({ retry: retry - 1 })
              }
            }
          }

          return null
        })
    }

    if (queryStore?.q && queryStore.q.length > 800) {
      return null
    }

    return await getImageSearchReponse({ retry: 1 })
  }

  async searchImages(
    offset: number,
    queryStore: any,
    mainStore: any,
  ): Promise<ImageSearchResponse | null> {
    const config = useRuntimeConfig()

    const searchParams: any = {
      appId: config.bingAppId,
      offset,
      count: 150,
      mkt: queryStore?.filterMarket,
      q: queryStore?.q,
    }

    if (queryStore.filterDate !== 'All') {
      searchParams.freshness = queryStore.filterDate
    }

    if (queryStore.filterSize !== 'All') {
      searchParams.size = queryStore.filterSize
    }

    const getImageSearchReponse = async ({
      retry,
    }: {
      retry: number
    }): Promise<ImageSearchResponse> => {
      return await $fetch('https://www.bingapis.com/api/v7/images/search', {
        headers:
          offset === 0
            ? getBaseHeaders(
                queryStore.q,
                mainStore?.connectingIp,
                mainStore?.userAgent,
              )
            : {},
        params: searchParams,
      })
        .then(function (res: { data: any }) {
          return parseImageResponse(res)
        })
        .catch(async function (error) {
          if (retry > 0) {
            if (error.code === 'ECONNRESET') {
              return await getImageSearchReponse({ retry: retry - 1 })
            }

            if (error.response) {
              if ([500, 502, 503].includes(error.response.status)) {
                return await getImageSearchReponse({ retry: retry - 1 })
              }
            }
          }

          return null
        })
    }

    if (queryStore?.q && queryStore.q.length > 800) {
      return null
    }

    return await getImageSearchReponse({ retry: 1 })
  }

  fetchSuggestions(locale: string, query: string): Promise<string[]> {
    return $fetch(
      'https://search.lilo.org/api/?service=suggestions&action=suggest',
      {
        params: {
          q: query,
          l: locale,
        },
      },
    )
      .then(function (res: { data: string[] }) {
        return res
      })
      .catch(() => {
        return []
      })
  }
}

// Desktop AD Units
const AD_UNIT_DESKTOP_FRANCE = '11647068'
const AD_UNIT_DESKTOP_EU = '11647071'
const AD_UNIT_DESKTOP_US = '11647069'
const AD_UNIT_DESKTOP_OTHER = '11647066'

// iOS AD Units
const AD_UNIT_IOS_FRANCE = '11704958'
const AD_UNIT_IOS_EU = '11704960'
const AD_UNIT_IOS_US = '11704961'
const AD_UNIT_IOS_OTHER = '11704959'

// Android AD Units
const AD_UNIT_ANDROID_FRANCE = '11704955'
const AD_UNIT_ANDROID_EU = '11704957'
const AD_UNIT_ANDROID_US = '11704962'
const AD_UNIT_ANDROID_OTHER = '11704956'

// Country codes
const COUNTRY_CODE_FRANCE = 'FR'
const COUNTRY_CODE_US = 'US'
const COUNTRY_CODES_EUROPE = ['IT', 'ES', 'DE', 'UK', 'PT', 'BE', 'CH']

export function getAdUnitId(
  countryCode: string,
  isAndroid: boolean,
  isIos: boolean,
): string {
  countryCode = countryCode.toUpperCase()

  if (isAndroid || isIos) {
    if (countryCode === COUNTRY_CODE_FRANCE) {
      return isAndroid ? AD_UNIT_ANDROID_FRANCE : AD_UNIT_IOS_FRANCE
    }

    if (COUNTRY_CODES_EUROPE.includes(countryCode)) {
      return isAndroid ? AD_UNIT_ANDROID_EU : AD_UNIT_IOS_EU
    }

    if (countryCode === COUNTRY_CODE_US) {
      return isAndroid ? AD_UNIT_ANDROID_US : AD_UNIT_IOS_US
    }

    return isAndroid ? AD_UNIT_ANDROID_OTHER : AD_UNIT_IOS_OTHER
  }

  if (countryCode === COUNTRY_CODE_FRANCE) {
    return AD_UNIT_DESKTOP_FRANCE
  }

  if (COUNTRY_CODES_EUROPE.includes(countryCode)) {
    return AD_UNIT_DESKTOP_EU
  }

  if (countryCode === COUNTRY_CODE_US) {
    return AD_UNIT_DESKTOP_US
  }

  return AD_UNIT_DESKTOP_OTHER
}
