export class RelatedSearch {
  text: string
  displayText: string
  thumbnailUrl: string | null

  constructor(
    text: string,
    displayText: string,
    thumbnailUrl: string | null = null,
  ) {
    this.text = text
    this.displayText = displayText
    this.thumbnailUrl = thumbnailUrl
  }
}
