<template>
  <div
    :class="{
      'mb-5': device.isDesktop,
      'mb-1 p-3 has-background-white': !device.isDesktop,
    }"
  >
    <div class="lilo-wikipedia-snippet">
      <div class="media px-5 mb-3">
        <figure v-if="hasThumbnail" class="has-rounded-corners media-left">
          <img :src="wikipediaData.thumbnailUrl" :alt="wikipediaData.title" />
        </figure>
        <div class="media-content">
          <h5 class="title is-size-4 is-size-5-touch is-capitalized">
            {{ wikipediaData.title }}
          </h5>
          <h6 class="subtitle is-size-6 is-two-lines">
            {{
              wikipediaData.description[0].toUpperCase() +
              wikipediaData.description.slice(1)
            }}
          </h6>
        </div>
      </div>
      <p class="content px-5 is-size-6 is-font-family-secondary">
        {{ extract }}
      </p>
      <hr class="has-background-grey-lighter" />
      <div class="px-5 is-size-6">
        <a :href="wikipediaData.pageUrl" class="has-text-primary">
          {{ translate('wikipedia.wikipedia') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { WikipediaResponse } from '~/model/WikipediaResponse'

export default defineComponent({
  name: 'WikipediaContent',

  props: {
    wikipediaData: {
      type: Object as PropType<WikipediaResponse>,
      default() {
        return null
      },
    },
  },

  computed: {
    extract(): string {
      if (typeof this.wikipediaData.extract.replaceAll !== 'function') {
        return this.wikipediaData.extract
      }

      return this.wikipediaData.extract
        .replaceAll(/{.*?}/g, '')
        .replaceAll(/\[.*?\]/g, '')
        .replaceAll(/\(.*?\)/g, '')
        .replaceAll(' ,', ',')
        .replaceAll(' .', '.')
    },
    hasThumbnail(): boolean {
      return this.wikipediaData.thumbnailUrl !== undefined
    },
  },
})
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';

img {
  height: 4.5rem;
}
hr {
  height: 1px;
}
@media screen and (max-width: $small) {
  .media-content {
    overflow-x: unset;
  }
}
</style>
