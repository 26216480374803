<template>
  <div
    class="multimedia-ad has-border-light-blue has-background-light-blue p-2 mb-5 is-inline-flex is-flex-direction-column"
  >
    <a
      class="image-container"
      :href="adData.url"
      :data-bingads-suffix="adData.adPingSuffix"
    >
      <img
        :src="device.isAndroid ? proxyImage(adData.imageUrl) : adData.imageUrl"
        :alt="adData.title"
      />
    </a>
    <div class="multimedia-ad-content py-2 px-4">
      <h4 class="has-text-weight-semibold has-text-primary is-size-5">
        <a :href="adData.url" :data-bingads-suffix="adData.adPingSuffix">{{
          adData.title
        }}</a>
      </h4>
      <p class="has-text-grey-dark is-size-6 is-font-family-secondary">
        <a
          class="has-text-grey-dark has-text-weight-medium"
          :href="adData.url"
          :data-bingads-suffix="adData.adPingSuffix"
          >{{ adData.displayUrl }}</a
        ><span class="end is-size-7">{{ translate('text_result.ad') }}</span>
      </p>
      <div class="columns mb-2">
        <div class="column p-0 is-two-thirds">
          <p class="has-text-grey-dark is-font-family-secondary is-size-6">
            {{ adData.description }}
          </p>
        </div>
        <div class="column p-0 is-one-third">
          <a
            class="lilo-button has-background-light-blue"
            :href="adData.url"
            :data-bingads-suffix="adData.adPingSuffix"
          >
            {{ 'Learn More' === adData.cta ? 'Découvrir' : adData.cta }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { MultimediaAd } from '~/model/MultimediaAd'

export default defineComponent({
  name: 'MultimediaAd',

  props: {
    adData: {
      type: Object as PropType<MultimediaAd>,
      default() {
        return null
      },
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/variables.scss';

.multimedia-ad {
  border-radius: 0.75rem;

  .image-container {
    height: 12rem;
    img {
      height: 12rem;
      width: 100%;
      object-fit: cover;
    }
  }

  .multimedia-ad-content {
    background-color: $white;
    border-radius: 0rem 0rem 0.75rem 0.75rem;

    p {
      .end {
        &:before {
          content: ' · ';
        }
      }
    }

    .columns {
      gap: 10px;
    }
  }
}
</style>
