<template>
  <div class="pl-3 content min-height mt-6">
    <p>
      Nous n’avons trouvé aucun résultat pour
      <strong>"{{ queryStore.q }}"</strong>
    </p>
    <p class="">Suggestions</p>
    <ul>
      <li>Vérifiez que tous les mots sont correctement orthographiés</li>
      <li>Essayez des mots-clés différents ou moins nombreux</li>
      <li>Essayez des mots-clés plus généraux</li>
      <li>Modifiez vos préférences de recherche</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NoResult',
})
</script>
