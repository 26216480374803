// @ts-nocheck
import { defineComponent, PropType } from 'vue'
import { NewsResult } from '~/model/NewsResult'

export default defineComponent({
  props: {
    newsData: {
      type: Object as PropType<NewsResult>,
      default() {
        return {}
      },
    },
  },

  computed: {
    provider(): Object | null {
      if (this.newsData.provider) {
        return this.newsData.provider[0]
      }

      return null
    },
    providerLogo(): string | null {
      if (this.provider && this.newsData.provider[0].image) {
        return this.newsData.provider[0].image.thumbnail.contentUrl
      }

      return null
    },
  },
})
