<template>
  <Modal classes="lilo-modal-large" :show="true">
    <template #header>
      <h4
        class="is-size-4 has-text-weight-semibold has-text-primary has-text-centered"
      >
        {{ translate('home.news_feed.configure.title') }}
      </h4>
      <img
        class="close"
        src="@/assets/images/icons/cross-dark.svg"
        width="12"
        alt="plus"
        @click="$emit('closeNewsFeedConfigureModal', false)"
      />
    </template>
    <template #body>
      <div v-if="feeds?.length === 0" class="has-text-centered">
        <img
          src="@/assets/images/loader-primary.svg"
          width="64px"
          alt="Loading"
        />
      </div>
      <form v-if="feeds?.length > 0" id="feedForm" class="columns is-multiline">
        <ConfigureItem
          v-for="(feed, index) in feeds"
          :key="'configure-feed-' + index"
          :feed="feed"
        />
      </form>
    </template>
    <template #footer>
      <div v-if="!updateError" class="is-flex is-justify-content-center">
        <button
          class="lilo-button-grey mr-2"
          @click="$emit('closeNewsFeedConfigureModal', false)"
        >
          {{ translate('home.news_feed.configure.cancel') }}
        </button>
        <button class="lilo-button-primary" @click="submitForm">
          {{ translate('home.news_feed.configure.submit') }}
        </button>
      </div>
      <div v-if="updateError" class="is-flex is-justify-content-center">
        <p class="has-text-danger">
          {{ translate('home.news_feed.configure.error') }}
        </p>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import ConfigureItem from './ConfigureItem.vue'
import { getFeeds, updateSubscribedFeeds } from '~/services/newsClient'

const feeds = ref([])
const updateError = ref(false)
const emit = defineEmits(['closeNewsFeedConfigureModal'])

onBeforeMount(async () => {
  feeds.value = await getFeeds()
})

async function submitForm(event) {
  event.preventDefault()
  // Get all checkboxes within the form with the id 'feedForm'
  const checkboxes = document.querySelectorAll(
    '#feedForm input[type="checkbox"]',
  )

  // Create an object to store the checkbox values
  const checkboxValues = []

  // Loop through each checkbox and store its value in the object
  checkboxes.forEach((checkbox) => {
    if (checkbox.checked) {
      checkboxValues.push(checkbox.name)
    }
  })

  const update = await updateSubscribedFeeds(checkboxValues)
  if (update === true) {
    emit('closeNewsFeedConfigureModal', true)
  }

  if (update !== true) {
    updateError.value = true
  }
}
</script>
