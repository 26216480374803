export class ProductAd {
  backgroundColor: string | null
  currency: string
  name: string
  price: number
  lowPrice: number | null
  rating: number | null
  sellerName: string
  shippingInfo: string
  thumbnailUrl: string
  url: string
  adPingSuffix: string

  constructor(
    backgroundColor: string | null,
    adPingSuffix: string,
    currency: string,
    name: string,
    price: number,
    lowPrice: number | null,
    sellerName: string,
    shippingInfo: string,
    thumbnailUrl: string,
    url: string,
    rating: number | null,
  ) {
    this.backgroundColor = backgroundColor
    this.currency = currency
    this.name = name
    this.price = price
    this.lowPrice = lowPrice
    this.sellerName = sellerName
    this.shippingInfo = shippingInfo
    this.thumbnailUrl = thumbnailUrl
    this.url = url
    this.rating = rating
    this.adPingSuffix = adPingSuffix
  }
}
