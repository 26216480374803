export class DeepLink {
  name: string
  url: string
  snippet: string | null

  constructor(name: string, url: string, snippet: string | null) {
    this.name = name
    this.url = url
    this.snippet = snippet
  }
}
