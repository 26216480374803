<template>
  <div
    :class="{
      media: displayThumbnail,
      'p-3 has-background-white': !device.isDesktop,
    }"
  >
    <div
      :class="!deepLinksGrid && resultData.thumbnailUrl ? 'media-content' : ''"
    >
      <BreadCrumb
        :url="encodeURI(resultData.url)"
        :display-url="encodeURI(resultData.displayUrl)"
        :is-ad="resultData.isAd"
        :ad-ping-suffix="resultData.adPingSuffix"
      />
      <a
        :href="resultData.url"
        class="is-size-5 is-size-6-touch my-1 has-text-primary has-text-weight-semibold is-one-line"
        :data-bingads-suffix="resultData.isAd ? resultData.adPingSuffix : null"
      >
        {{ resultData.name }}
      </a>
      <p
        class="is-two-lines has-text-grey-darker is-size-6 is-size-7-touch is-font-family-secondary"
      >
        {{ resultData.snippet }}
      </p>
      <div v-if="deepLinks.length > 0">
        <div
          v-if="deepLinksGrid"
          class="lilo-text-result-sublinks-grid columns is-multiline mt-2"
        >
          <div
            v-for="(item, deepLinkIndex) in deepLinks"
            :key="'sublink-' + deepLinkIndex"
            class="column is-half"
            :class="{
              'pt-0 pb-3': device.isDesktop,
              'px-1 py-2': !device.isDesktop,
            }"
          >
            <a
              :href="item.url"
              class="has-text-primary is-size-5 is-size-6-touch has-text-weight-medium"
              :data-bingads-suffix="
                resultData.isAd ? resultData.adPingSuffix : null
              "
            >
              {{ item.name }}
            </a>
            <p
              v-if="!device.isMobile"
              class="is-two-lines is-size-6 is-font-family-secondary"
            >
              {{ item.snippet }}
            </p>
          </div>
        </div>
        <div v-else class="is-inline-block">
          <div
            class="lilo-text-result-sublinks"
            :class="{
              'pt-2': !device.isDesktop,
            }"
          >
            <a
              v-for="(item, deepLinkIndex) in deepLinks"
              :key="'sublink-' + deepLinkIndex"
              :href="item.url"
              class="has-text-primary is-size-6 is-size-7-touch"
              :class="{
                'is-hidden': item.name.length < 1,
                'has-border-light-blue has-text-weight-medium lilo-border-radius py-2 px-2':
                  !device.isDesktop,
                'has-text-weight-semibold': device.isDesktop,
              }"
            >
              {{ item.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <figure v-if="displayThumbnail" class="media-right has-rounded-corners">
      <img
        :src="
          device.isAndroid
            ? proxyImage(resultData.thumbnailUrl)
            : resultData.thumbnailUrl
        "
        :alt="resultData.name"
      />
    </figure>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

import BreadCrumb from './BreadCrumb.vue'
import { TextResult as TextResultModel } from '~/model/TextResult'
import { DeepLink } from '~/model/DeepLink'

export default defineComponent({
  name: 'TextResult',

  components: {
    BreadCrumb,
  },

  props: {
    index: {
      type: Number,
      default() {
        return 0
      },
    },
    resultData: {
      type: Object as PropType<TextResultModel>,
      default() {
        return null
      },
    },
  },

  computed: {
    deepLinks(): Array<DeepLink> {
      if (
        this.resultData.deepLinks !== undefined &&
        this.resultData.deepLinks.length > 6
      ) {
        const data = this.resultData.deepLinks
        data.length = 4

        return data
      }

      return this.resultData.deepLinks
    },
    deepLinksGrid({ device }): Boolean {
      if (this.index !== 0 || this.resultData.isAd) {
        return false
      }

      if (device.isDesktop) {
        for (const index in this.deepLinks) {
          if (!this.deepLinks[index].snippet) {
            return false
          }
        }
      }

      return true
    },
    displayThumbnail(): Boolean {
      return (
        !this.resultData.isAd &&
        this.index !== 0 &&
        this.resultData.thumbnailUrl
      )
    },
  },
})
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/variables.scss';
@import '~/assets/scss/utils/breakpoints.scss';

.lilo-text-result {
  a {
    text-overflow: clip;
  }

  a:visited {
    color: $purple !important;
  }

  img {
    height: 5rem;
  }
}

@media screen and (min-width: $large) {
  .lilo-ad-result,
  .lilo-text-result {
    margin-bottom: 2rem;
  }

  .lilo-text-result-sublinks a:not(:first-child)::before {
    content: ' · ';
  }

  .lilo-text-result-sublinks a {
    text-decoration: none;
  }

  .lilo-text-result-sublinks a:not(:first-child) {
    margin-left: 0.25rem;
  }

  .media-right {
    img {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: $large - 1) {
  .is-inline-block {
    max-width: 100%;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .lilo-text-result-sublinks {
      line-height: 2.3rem;
      min-width: max-content;

      a {
        padding: 0.2em 0.5em;
        margin-right: 0.5em;
        border-radius: 1rem;
        font-size: 0.75rem;
      }
    }
  }

  .media-right {
    img {
      margin-top: 0.5rem;
    }
  }
}
</style>
