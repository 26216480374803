<template>
  <Modal
    :show="showSelf"
    classes="lilo-modal"
    body-classes="py-0 has-text-centered"
  >
    <template #header>
      <div class="is-flex is-justify-content-space-around py-3">
        <h3 class="is-size-3 has-text-primary has-text-weight-semibold">
          {{ translate('modal.tribe_welcome.header.title') }} 🎉
        </h3>
      </div>
      <img
        class="close"
        src="@/assets/images/icons/cross-dark.svg"
        width="12"
        alt="plus"
        @click="hideSelf"
      />
    </template>
    <template #body>
      <div class="content">
        <h5 class="mb-4">
          {{ translate('modal.tribe_welcome.content.welcome') }}
          <span class="has-text-weight-bold">{{
            queryStore.userInfo.tribe.name
          }}</span>
        </h5>

        <blockquote v-if="queryStore.userInfo.tribe?.welcome_message">
          {{ queryStore.userInfo.tribe.welcome_message }}
        </blockquote>
        <p v-if="queryStore.userInfo.tribe?.project">
          {{ translate('modal.tribe_welcome.content.project_1') }}
          <a
            class="has-text-weight-bold"
            :href="'https://lilo.org/' + queryStore.userInfo.tribe.project.slug"
            >{{ queryStore.userInfo.tribe.project.name }}</a
          >{{ translate('modal.tribe_welcome.content.project_2') }}
        </p>
      </div>
    </template>
    <template #footer>
      <div class="is-flex is-justify-content-center">
        <button class="lilo-button-primary" @click="hideSelf">
          {{ translate('cta.understood') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useMainStore } from '~/store/index'

const showSelf = ref(true)

const hideSelf = () => {
  showSelf.value = false

  const mainStore = useMainStore()
  mainStore.displayTribeWelcome = false
}
</script>

<style scoped lang="scss"></style>
