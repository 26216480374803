import { Computation } from './Computation'
import type { MultimediaAd } from './MultimediaAd'
import { NewsResult } from '~/model/NewsResult'
import { RelatedSearch } from '~/model/RelatedSearch'
import { TextResult } from '~/model/TextResult'
import { VideoResult } from '~/model/VideoResult'
import { ProductAd } from '~/model/ProductAd'
import { WikipediaResponse } from '~/model/WikipediaResponse'
import { ImageResult } from '~/model/ImageResult'
import { Pagination } from '~/model/Pagination'
import { PlaceResult } from '~/model/PlaceResult'

export class WebSearchResponse {
  alterationDisplayQuery: string | null
  alterationOverrideQuery: string | null
  computation: Computation | null
  images: Array<ImageResult>
  mainlineRankingResponse: Array<string>
  news: Array<NewsResult>
  pagination: Pagination
  places: Array<PlaceResult>
  relatedSearches: Array<RelatedSearch>
  productAds: Array<ProductAd>
  textResults: Array<TextResult>
  topAds: Array<TextResult>
  videoResults: Array<VideoResult>
  wikipediaData!: WikipediaResponse | null
  multimediaAd: MultimediaAd | null

  constructor(
    images: Array<ImageResult>,
    topAds: Array<TextResult>,
    productAds: Array<ProductAd>,
    relatedSearches: Array<RelatedSearch>,
    news: Array<NewsResult>,
    textResults: Array<TextResult>,
    mainlineRankingResponse: Array<string>,
    videoResults: Array<VideoResult>,
    pagination: Pagination,
    places: Array<PlaceResult>,
    computation: Computation | null,
    alterationDisplayQuery: string | null,
    alterationOverrideQuery: string | null,
    multimediaAd: MultimediaAd | null,
  ) {
    this.images = images
    this.topAds = topAds
    this.productAds = productAds
    this.relatedSearches = relatedSearches
    this.news = news
    this.textResults = textResults
    this.mainlineRankingResponse = mainlineRankingResponse
    this.videoResults = videoResults
    this.pagination = pagination
    this.places = places
    this.computation = computation
    this.alterationDisplayQuery = alterationDisplayQuery
    this.alterationOverrideQuery = alterationOverrideQuery
    this.multimediaAd = multimediaAd
  }

  toJSON() {
    return { ...this }
  }
}
