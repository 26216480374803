<template>
  <div
    :class="{
      'mb-6': device.isDesktop,
      'mb-1 p-3 has-background-white': !device.isDesktop,
    }"
  >
    <SectionTitle :title="translate('section_title.videos')" />
    <div v-if="device.isDesktop" class="columns is-multiline">
      <VideoGridItem
        v-for="(item, index) in videoGridData"
        :key="'video-result-' + index"
        :video-data="item"
        class="column is-full p-0"
      />
    </div>
    <div v-if="!device.isDesktop" class="mt-3">
      <LiloCarousel :slides-per-page="device.isTablet ? 4 : 2.2">
        <template #slides>
          <Slide
            v-for="(item, index) in videoResults"
            :key="'video-result-slide' + index"
          >
            <VideoSliderItem
              :key="'video-result-' + index"
              :video-data="item"
              class="slide"
              :index="index"
            />
          </Slide>
        </template>
      </LiloCarousel>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Slide } from 'vue3-carousel'
import VideoGridItem from './VideoGridItem.vue'
import VideoSliderItem from './VideoSliderItem.vue'
import LiloCarousel from '~/components/LiloCarousel.vue'
import SectionTitle from '~/components/web/SectionTitle.vue'
import { VideoResult as VideoResultModel } from '~/model/VideoResult'

export default defineComponent({
  name: 'VideoResults',

  components: {
    LiloCarousel,
    SectionTitle,
    VideoGridItem,
    VideoSliderItem,
    Slide,
  },

  props: {
    videoResults: {
      type: Array as PropType<Array<VideoResultModel>>,
      default() {
        return []
      },
    },
  },
  computed: {
    videoGridData(): Array<VideoResultModel> {
      const gridData: Array<VideoResultModel> = this.videoResults

      if (gridData.length > 3) {
        gridData.length = 3
      }

      return gridData
    },
  },
})
</script>
