<template>
  <div
    class="columns is-multiline pl-0"
    :class="{
      'pr-6 mt-3': device.isDesktop,
      'pr-0 mt-1': !device.isDesktop,
    }"
  >
    <div
      class="column"
      :class="{
        'is-three-fifths pr-6': device.isDesktop,
        'is-full has-background-grey-lighter pt-0 px-0 pb-1': !device.isDesktop,
      }"
    >
      <div
        v-if="searchResult.alterationDisplayQuery"
        :class="{
          'mb-5': device.isDesktop,
          'has-background-white p-3 mb-1': !device.isDesktop,
        }"
      >
        <p class="is-size-5">
          {{ translate('altered_query.results_for') }}
          <span class="has-text-weight-bold is-capitalized">
            {{ searchResult.alterationDisplayQuery }}
          </span>
        </p>
        <p>
          {{ translate('altered_query.try_original') }}
          <a
            :href="
              '/?action=web&q=' +
              encodeURIComponent(searchResult.alterationOverrideQuery)
            "
            class="has-text-weight-semibold is-capitalized"
            >{{ queryStore.q }}</a
          >
        </p>
      </div>
      <div
        v-if="searchResult.productAds.length > 4"
        :class="{
          'mb-6': device.isDesktop,
          'mb-1 p-3 has-background-white': !device.isDesktop,
        }"
      >
        <h4
          class="title is-size-7 has-text-weight-light has-text-grey has-text-right mb-1 pr-2"
        >
          {{ translate('section_title.ads') }}
        </h4>
        <LiloCarousel
          v-if="searchResult.productAds.length > 0"
          :slides-per-page="device.isDesktop ? 5 : 2.5"
        >
          <template #slides>
            <Slide
              v-for="(item, index) in searchResult.productAds"
              :key="'product-ad-carousel-slide-' + index"
            >
              <ProductSliderItem
                :key="'product-ad-' + index"
                :product-data="item"
                class="slide"
                :index="index"
              />
            </Slide>
          </template>
        </LiloCarousel>
      </div>
      <ImageResults
        v-if="
          searchResult.images.length > 0 && searchResult.productAds.length === 0
        "
        :image-results="searchResult.images"
      />
      <TextResult
        v-for="(item, index) in searchResult.topAds"
        :key="'search-top-ad-' + index"
        :result-data="item"
        class="lilo-ad-result"
        :class="{
          'mb-1': !device.isDesktop,
        }"
      />
      <WikipediaContent
        v-if="searchResult.wikipediaData && !device.isDesktop"
        :wikipedia-data="searchResult.wikipediaData"
      />
      <PlacesResults
        v-if="searchResult.places.length && !device.isDesktop"
        :places-data="
          device.isIos ? searchResult.places.slice(0, 2) : searchResult.places
        "
      />
      <div
        v-for="(item, index) in searchResult.textResults"
        :key="'search-result-' + index"
      >
        <VideoResults
          v-if="searchResult.videoResults && videosPosition === index"
          :video-results="searchResult.videoResults"
        />
        <NewsResults
          v-if="searchResult.news && newsPosition === index"
          :news-results="searchResult.news"
        />
        <TextResult
          :result-data="item"
          :index="index"
          class="lilo-text-result"
          :class="{
            'mb-1':
              !device.isDesktop &&
              index !== searchResult.textResults.length - 1,
          }"
        />
      </div>
      <TextResult
        v-for="(item, index) in searchResult.topAds"
        :key="'search-top-ad-' + index"
        :result-data="item"
        class="lilo-ad-result"
        :class="{
          'mb-1': !device.isDesktop,
        }"
      />
    </div>
    <div v-if="device.isDesktop" class="column is-two-fifths">
      <WikipediaContent
        v-if="searchResult.wikipediaData"
        :wikipedia-data="searchResult.wikipediaData"
      />
      <MultimediaAd
        v-if="searchResult.multimediaAd"
        :ad-data="searchResult.multimediaAd"
      />
      <PlacesResults
        v-if="searchResult.places.length"
        :places-data="searchResult.places"
      />
    </div>
    <div
      class="column"
      :class="{
        'is-three-fifths': device.isDesktop,
        'is-full': !device.isDesktop,
      }"
    >
      <SectionTitle
        v-if="searchResult.relatedSearches.length > 0"
        :title="translate('section_title.related_searches')"
      />
      <div class="columns is-multiline">
        <RelatedSearch
          v-for="(item, index) in searchResult.relatedSearches"
          :key="'related-search-' + index"
          :related-search="item"
          class="column is-half-desktop is-full py-2 pl-0 is-size-6"
        />
        <SearchPagination :pagination="searchResult.pagination" />
      </div>
      <a
        href="https://privacy.microsoft.com/privacystatement"
        class="has-text-grey has-font-secondary is-size-6"
      >
        {{ translate('results.by_microsoft') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Slide } from 'vue3-carousel'
import ProductSliderItem from './ads/ProductSliderItem.vue'
import ImageResults from './images/ImageResults.vue'
import RelatedSearch from './RelatedSearch.vue'
import SectionTitle from './SectionTitle.vue'
import NewsResults from './news/NewsResults.vue'
import PlacesResults from './places/PlacesResults.vue'
import TextResult from './result/TextResult.vue'
import VideoResults from './videos/VideoResults.vue'
import WikipediaContent from './wikipedia/WikipediaContent.vue'
import MultimediaAd from './ads/MultimediaAd.vue'
import LiloCarousel from '~/components/LiloCarousel.vue'
import SearchPagination from '~/components/SearchPagination.vue'
import { WebSearchResponse } from '~/model/WebSearchResponse'

export default defineComponent({
  name: 'WebSearch',

  components: {
    LiloCarousel,
    ImageResults,
    PlacesResults,
    ProductSliderItem,
    NewsResults,
    RelatedSearch,
    SearchPagination,
    SectionTitle,
    TextResult,
    VideoResults,
    WikipediaContent,
    Slide,
    MultimediaAd,
  },

  props: {
    searchResult: {
      type: Object as PropType<WebSearchResponse>,
      default() {
        return null
      },
    },
  },

  computed: {
    newsPosition(): number | null {
      let position = null

      this.searchResult.mainlineRankingResponse.forEach(
        (value: string, index: number) => {
          if (value === 'News') {
            position = index - 1
          }
        },
      )

      return position
    },
    videosPosition(): number | null {
      let position = null

      this.searchResult.mainlineRankingResponse.forEach(
        (value: string, index: number) => {
          if (value === 'Videos') {
            position = index - 1
          }
        },
      )

      return position
    },
  },
})
</script>
