export class PaginationLink {
  pageNumber: number
  offset: number

  constructor(pageNumber: number, offset: number) {
    this.pageNumber = pageNumber
    this.offset = offset
  }
}

export class Pagination {
  currentPageNumber: number
  pages: Array<PaginationLink>

  constructor(currentPageNumber: number, pages: Array<PaginationLink>) {
    this.currentPageNumber = currentPageNumber
    this.pages = pages
  }
}
