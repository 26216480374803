<template>
  <div
    class="columns is-multiline pl-0"
    :class="{
      'pr-6 mt-3': device.isDesktop,
      'pr-0': !device.isDesktop,
    }"
  >
    <div
      class="column"
      :class="{
        'is-three-fifths pr-6': device.isDesktop,
        'is-full has-background-light-blue pt-0 px-1 py-1': !device.isDesktop,
      }"
    >
      <NewsResult
        v-for="(item, index) in newsResults"
        :key="'search-news-' + index"
        :news-data="item"
        class="lilo-news-result"
        :class="{
          'mb-1': !device.isDesktop,
          'mb-3': device.isDesktop,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { NewsResult as NewsResultModel } from '~/model/NewsResult'
import NewsResult from '~/components/news/NewsResult.vue'

export default defineComponent({
  name: 'NewsSearch',

  components: { NewsResult },

  props: {
    newsResults: {
      type: Array as PropType<Array<NewsResultModel>>,
      default() {
        return null
      },
    },
  },
})
</script>

<style scoped></style>
