/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime'

export default defineNuxtPlugin(({ vueApp }) => {
  const helpersMixin = {
    methods: {
      capitalize(value: string): string {
        if (!value) return ''
        return value.toString().charAt(0).toUpperCase() + value.slice(1)
      },
      getTimeSince(date: string, lang: string): string {
        dayjs.locale(lang || 'fr')
        dayjs.extend(relativeTime)
        return this.capitalize(dayjs(date).fromNow())
      },
      getTimeSinceFromTimeStamp(date: string, lang: string): string {
        dayjs.locale(lang || 'fr')
        dayjs.extend(relativeTime)
        return this.capitalize(dayjs.unix(date).fromNow())
      },
    },
  }

  vueApp.mixin(helpersMixin)
})
