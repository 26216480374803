@
<template>
  <Modal :show="show" :classes="'antibot'">
    <template #header>
      <h4
        class="is-size-4 has-text-weight-semibold has-text-primary has-text-centered"
      >
        {{ translate('modal.antibot.title') }}
      </h4>
    </template>
    <template #body>
      <div class="antibot__content">
        <div class="antibot__description">
          {{ translate('modal.antibot.description') }} 🚀
        </div>
        <hr class="divider" />

        <div v-if="skeletonVisible" class="antibot__loading">
          <div class="antibot__loading-test">
            {{ translate('modal.antibot.loading_test') }}
          </div>
          <Skeleton height="20px" width="100px" />
          <Skeleton height="40px" width="100%" margin="10px" />
          <Skeleton height="40px" width="200px" margin="10px" />
        </div>

        <form v-else class="antibot__form" @submit="checkUserInput">
          <div class="antibot__captcha">
            {{ translate('modal.antibot.question') }}
            <br />
            <b>
              <span class="antibot__captcha--underline">
                {{ captchaQuery.substring(0, 3) }}</span
              >{{ captchaQuery.substring(3) }}
            </b>
            <br />
          </div>
          <input ref="input" type="text" class="antibot__input" />
          <button type="submit" class="antibot__action">
            {{ translate('cta.validate') }}
          </button>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="antibot__footer">
        <a
          class="antibot__more"
          href="https://support.lilo.org/docs/support/mon-adresse-ip-est-bloquee/"
          target="_blank"
        >
          {{ translate('modal.antibot.footer.more') }}
        </a>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ModalAntibot',

  props: {
    show: Boolean,
  },

  emits: ['open-modal-result'],

  data() {
    return {
      skeletonVisible: true,
      captchaQuery: '',
    }
  },

  async created() {
    this.captchaQuery = await $fetch('/api/country-captcha')
    setTimeout(() => {
      this.skeletonVisible = false
      this.$nextTick(() => {
        this.$refs.input?.focus()
      })
    }, 2500)
  },

  methods: {
    checkUserInput(evt) {
      evt.preventDefault()

      let success = false
      if (
        this.$refs.input.value.toLowerCase() ===
        this.captchaQuery.substring(0, 3).toLowerCase()
      ) {
        success = true
      }

      this.$emit('open-modal-result', success)
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';
@import '~/assets/scss/utils/variables.scss';

.antibot {
  width: 500px;
  padding: 30px;
  margin: auto 10px;
  border-radius: 20px;
  position: relative;
  text-align: center;

  @media screen and (min-width: $large) {
    margin: auto;
  }
}

.antibot__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.antibot__description {
  color: $grey-dark;
  padding-bottom: 10px;
}

.antibot__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.antibot__loading-test {
  color: $grey-dark;
  margin-bottom: 5px;
}

.antibot__captcha--underline {
  text-decoration: underline;
}

.antibot__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.antibot__input {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid $grey-light;

  &:focus {
    outline: none;
    border: 1px solid #bbddee;
    box-shadow: 0 0 1px 1px rgba($primary-light, 0.06);
  }
}

.antibot__action {
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  width: 200px;
  height: 40px;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 500px;
  background-color: $secondary;

  &:hover {
    filter: brightness(110%);
  }
}

.antibot__footer {
  font-size: 16px;
  text-align: center;
  line-height: 1;
  text-decoration: underline;
  margin-top: 20px;
}

.divider {
  height: 1px;
  width: 100%;
  margin: 15px 0;
  background-color: #909090;
}
</style>
