export class PlaceResult {
  address: string
  description: string
  imageUrl: string | null
  latitude: number | null
  longitude: number | null
  name: string
  phoneNumber: string | null
  rating: number | null
  ratingCount: number | null
  schedule: string | null
  website: string | null

  constructor(
    address: string,
    description: string,
    imageUrl: string | null,
    latitude: number | null,
    longitude: number | null,
    name: string,
    phoneNumber: string | null,
    rating: number | null,
    ratingCount: number | null,
    schedule: string | null,
    website: string | null,
  ) {
    this.address = address
    this.description = description
    this.imageUrl = imageUrl
    this.latitude = latitude
    this.longitude = longitude
    this.name = name
    this.phoneNumber = phoneNumber
    this.rating = rating
    this.ratingCount = ratingCount
    this.schedule = schedule
    this.website = website
  }
}
