import { joinURL } from 'ufo'
import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin({
  name: 'lilo:file-error-handler',
  setup() {
    const router = useRouter()
    const config = useRuntimeConfig()

    function reloadAppAtPath(to: RouteLocationNormalized) {
      const isHash = 'href' in to && (to.href as string)[0] === '#'
      const path = isHash
        ? config.app.baseURL + (to as any).href
        : joinURL(config.app.baseURL, to.fullPath)
      reloadNuxtApp({ path, persistState: true })
    }

    router.onError((error, to) => {
      if (
        error.message.includes('dynamically imported module') ||
        error.message.includes('Importing a module script failed') ||
        error.message.includes('to preload CSS for')
      ) {
        reloadAppAtPath(to)
      }
    })
  },
})
