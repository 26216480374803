export class NewsFeed {
  id: string
  isDefault: boolean
  name: string
  thumbnailUrl: string | null
  description: string
  subscribed: boolean
  hasPaidArticles: boolean

  constructor(
    id: string,
    name: string,
    description: string,
    subscribed: boolean,
    hasPaidArticles: boolean,
    isDefault: boolean,
    thumbnailUrl: string | null = null,
  ) {
    this.id = id
    this.name = name
    this.thumbnailUrl = thumbnailUrl
    this.description = description
    this.subscribed = subscribed
    this.isDefault = isDefault
    this.hasPaidArticles = hasPaidArticles
  }
}
