export default defineNuxtPlugin(({ vueApp }) => {
  const imageMixin = {
    methods: {
      proxyImage(imageUrl: string): string {
        return `https://ws.lilo.org/image/forward?url=${encodeURIComponent(
          imageUrl,
        )}`
      },
    },
  }

  vueApp.mixin(imageMixin)
})
