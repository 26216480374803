import { WikipediaResponse } from '~/model/WikipediaResponse'

function parseResponse(page: any): WikipediaResponse {
  return new WikipediaResponse(
    page.terms.description[0],
    page.extract,
    page.canonicalurl,
    page.thumbnail?.source,
    page.terms.label[0],
  )
}

export class WikipediaClient {
  async fetch(title: string, lang: string): Promise<WikipediaResponse> {
    return await $fetch(`https://${lang}.wikipedia.org/w/api.php`, {
      params: {
        action: 'query',
        exintro: 1,
        explaintext: 1,
        exsectionformat: 'plain',
        exsentences: 2,
        format: 'json',
        formatversion: 2,
        inprop: 'url',
        origin: '*',
        pithumbsize: 300,
        prop: 'info|extracts|pageimages|pageterms', // |revisions',
        redirects: 1,
        titles: title,
      },
    })
      .then(function (res: { data: any }): WikipediaResponse {
        return parseResponse(res.query.pages[0])
      })
      .catch(function (error) {
        throw new Error(error)
      })
  }
}
