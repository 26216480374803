<template>
  <a :href="targetUrl">
    <div class="lilo-related-search">
      <span class="icon-text">
        <span class="icon">
          <img src="@/assets/images/icons/search-grey.svg" alt="grey icon" />
        </span>
        <span class="has-text-weight-medium has-text-primary">
          {{ relatedSearch.displayText }}
        </span>
      </span>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { RelatedSearch } from '~/model/RelatedSearch'

export default defineComponent({
  name: 'RelatedSearch',

  props: {
    relatedSearch: {
      type: Object as PropType<RelatedSearch>,
      default() {
        return null
      },
    },
  },

  computed: {
    targetUrl(): string {
      return `?q=${this.relatedSearch.text}`
    },
  },
})
</script>
