export class VideoResult {
  contentUrl: string
  datePublished: string
  name: string
  thumbnailUrl: string
  publisherName: string | null
  duration: string | null
  description: string | null

  constructor(
    contentUrl: string,
    datePublished: string,
    duration: string | null,
    name: string,
    thumbnailUrl: string,
    publisherName: string | null,
    description: string | null,
  ) {
    this.contentUrl = contentUrl
    this.datePublished = datePublished
    this.duration = duration
    this.name = name
    this.thumbnailUrl = thumbnailUrl
    this.publisherName = publisherName
    this.description = description
  }
}
