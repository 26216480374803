import unhead_KgADcZ0jPj from "/home/elbisna/search/source/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/elbisna/search/source/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/elbisna/search/source/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/elbisna/search/source/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/elbisna/search/source/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/elbisna/search/source/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/elbisna/search/source/.nuxt/floating-vue.mjs";
import plugin_t2GMTTFnMT from "/home/elbisna/search/source/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_1UohGbtF8v from "/home/elbisna/search/source/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_didomi_client_MQTGM8p4cQ from "/home/elbisna/search/source/plugins/01-didomi.client.ts";
import clarity_client_3WY6KrZTY9 from "/home/elbisna/search/source/plugins/clarity.client.ts";
import device_PxhEqcDcOg from "/home/elbisna/search/source/plugins/device.ts";
import fileErrorHandler_client_Hgk6EaU0kf from "/home/elbisna/search/source/plugins/fileErrorHandler.client.ts";
import helpers_mgWmzbOuLB from "/home/elbisna/search/source/plugins/helpers.ts";
import pinia_48xmdi2HHl from "/home/elbisna/search/source/plugins/pinia.ts";
import proxyImage_7TDD8KH2jT from "/home/elbisna/search/source/plugins/proxyImage.ts";
import sentry_client_shVUlIjFLk from "/home/elbisna/search/source/plugins/sentry.client.ts";
import translator_UTlq7tYppt from "/home/elbisna/search/source/plugins/translator.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_t2GMTTFnMT,
  plugin_1UohGbtF8v,
  _01_didomi_client_MQTGM8p4cQ,
  clarity_client_3WY6KrZTY9,
  device_PxhEqcDcOg,
  fileErrorHandler_client_Hgk6EaU0kf,
  helpers_mgWmzbOuLB,
  pinia_48xmdi2HHl,
  proxyImage_7TDD8KH2jT,
  sentry_client_shVUlIjFLk,
  translator_UTlq7tYppt
]