import { ImageResult } from '~/model/ImageResult'
import { RelatedSearch } from '~/model/RelatedSearch'

export class ImageSearchResponse {
  images: Array<ImageResult>
  relatedSearches: Array<RelatedSearch>
  nextOffset: number | null

  constructor(
    nextOffset: number | null,
    images: Array<ImageResult>,
    relatedSearches: Array<RelatedSearch>,
  ) {
    this.images = images
    this.nextOffset = nextOffset
    this.relatedSearches = relatedSearches
  }

  toJSON() {
    return { ...this }
  }
}
