<template>
  <div>
    <div
      class="has-border-light-blue lilo-border-radius"
      :class="{
        'has-background-white': !device.isDesktopOrTablet,
        'px-1 pb-3': device.isDesktopOrTablet,
      }"
    >
      <div class="columns">
        <div v-if="!device.isDesktopOrTablet" class="column is-full pb-0">
          <a
            :href="videoData.contentUrl"
            class="has-text-primary is-size-6 has-text-weight-medium is-two-lines"
          >
            {{ videoData.name }}
          </a>
          <div class="mt-1 is-size-7 has-text-grey-dark">
            <p class="has-text-weight-semibold">
              {{ videoData.publisherName }}
              <span class="has-text-grey">{{
                getTimeSince(videoData.datePublished, mainStore.locale)
              }}</span>
            </p>
          </div>
        </div>
        <a
          :href="videoData.contentUrl"
          class="column is-one-third-desktop is-two-thirds-touch"
        >
          <figure class="image is-4by3">
            <img :src="videoData.thumbnailUrl" :alt="videoData.name" />
          </figure>
        </a>
        <div v-if="device.isDesktopOrTablet" class="column pl-0">
          <a
            :href="videoData.contentUrl"
            class="has-text-primary is-size-5 has-text-weight-medium is-two-lines"
          >
            {{ videoData.name }}
          </a>
          <div class="mt-1 is-size-6 has-text-grey-dark">
            <p class="has-text-weight-semibold">
              {{ videoData.publisherName }}
              <span class="has-text-grey">{{
                getTimeSince(videoData.datePublished, mainStore.locale)
              }}</span>
            </p>
            <p class="mt-1 is-two-lines is-font-family-secondary">
              {{ videoData.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { VideoResult } from '~/model/VideoResult'

export default defineComponent({
  name: 'VideoResult',

  props: {
    videoData: {
      type: Object as PropType<VideoResult>,
      default() {
        return {}
      },
    },
  },
})
</script>

<style scoped lang="scss">
figure {
  width: 100%;
}

span.has-text-grey:before {
  content: ' · ';
}
</style>
