<template>
  <h4 class="title is-size-6 has-text-grey-dark mb-1">
    {{ title }}
  </h4>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SectionTitle',

  props: {
    title: {
      type: String,
      default() {
        return null
      },
    },
  },
})
</script>

<style scoped>
h4 {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
</style>
