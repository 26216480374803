<template>
  <div class="column card is-full my-1 has-border-light-blue">
    <div class="media mb-2">
      <div class="media-left">
        <figure
          class="image"
          :class="{
            'is-64x64': device.isDesktop,
            'is-48x48': !device.isDesktop,
          }"
        >
          <NuxtImg
            :src="feed.thumbnailUrl"
            :alt="feed.name"
            placeholder="/placeholder-1by1.svg"
          />
        </figure>
      </div>
      <div class="media-content">
        <p class="has-text-primary is-size-5 has-text-weight-semibold">
          {{ feed.name }}
        </p>
        <p
          class="is-font-family-secondary"
          :class="{
            'is-size-6': device.isDesktop,
            'is-two-lines is-size-7': !device.isDesktop,
          }"
        >
          {{ feed.description }}
        </p>
      </div>
      <label class="toggle" :for="feed.id" @click="labelClick">
        <input
          :id="feed.id"
          class="toggle__input"
          :name="feed.id"
          :checked="checked"
          type="checkbox"
        />
        <div class="toggle__fill"></div>
      </label>
    </div>
    <p v-if="feed.hasPaidArticles" class="has-text-danger is-size-7">
      {{ translate('home.news_feed.configure.item.is_paid') }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { NewsFeed } from '~/model/NewsFeed'

const props = defineProps({
  feed: {
    type: Object as PropType<NewsFeed>,
    default: null,
  },
})

const checked = ref(props.feed.subscribed)
function labelClick(event) {
  event.preventDefault()
  checked.value = !checked.value
}
</script>

<style lang="scss" scoped>
.image img {
  height: 100%;
  object-fit: cover;
}

.has-border-light-blue {
  border-radius: 0.75rem;
}
</style>
