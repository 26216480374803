<template>
  <div
    class="columns is-multiline pl-0"
    :class="{
      'pr-6 mt-3': device.isDesktop,
      'pr-0': !device.isDesktop,
    }"
  >
    <div
      class="column"
      :class="{
        'is-three-fifths pr-6': device.isDesktop,
        'is-full has-background-light-blue pt-0 px-1 py-1':
          !device.isDesktopOrTablet,
      }"
    >
      <VideoResult
        v-for="(item, index) in videoResults"
        :key="'search-video-' + index"
        :video-data="item"
        class="lilo-video-result"
        :class="{
          'mb-1': !device.isDesktop,
          'mb-3': device.isDesktop,
        }"
      />
      <a
        href="https://www.youtube.com/static?template=terms"
        class="has-text-grey has-font-secondary is-size-6 is-block"
      >
        {{ translate('results.by_youtube') }}
      </a>
      <a
        href="https://policies.google.com/privacy"
        class="has-text-grey has-font-secondary is-size-6 is-block"
      >
        {{ translate('results.by_youtube_2') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import VideoResult from './VideoResult.vue'
import { VideoResult as VideoResultModel } from '~/model/VideoResult'

export default defineComponent({
  name: 'VideoSearch',

  components: {
    VideoResult,
  },

  props: {
    videoResults: {
      type: Array as PropType<Array<VideoResultModel>>,
      default() {
        return null
      },
    },
  },
})
</script>
