import { VideoResult } from '~/model/VideoResult'
import { VideoSearchResponse } from '~/model/VideoSearchResponse'

export function parseVideoResponse(data: any): VideoSearchResponse {
  const videos: Array<VideoResult> = []
  for (const index in data.items) {
    const result = data.items[index].snippet
    let title = result.title
    title = title.replace('&#39;', "'")
    title = title.replace('&quot;', '"')

    videos.push(
      new VideoResult(
        'https://www.youtube.com/watch?v=' + data.items[index].id.videoId,
        result.publishedAt,
        null,
        title,
        result.thumbnails.high.url ??
          result.thumbnails.medium.url ??
          result.thumbnails.default.url,
        result.channelTitle,
        result.description,
      ),
    )
  }

  return new VideoSearchResponse(videos)
}

export class YoutubeClient {
  async videos(query: string): Promise<VideoSearchResponse | void> {
    const config = useRuntimeConfig()

    return await $fetch('https://www.googleapis.com/youtube/v3/search', {
      params: {
        part: 'snippet',
        maxResults: 50,
        key: config.youtubeKey,
        q: query,
        type: 'video',
      },
    })
      .then(function (res: { data: any }) {
        return parseVideoResponse(res)
      })
      .catch(function (error) {
        if (error.response) {
          if ([403].includes(error.response.status)) {
            return parseVideoResponse({ items: [] })
          }
        }

        throw new Error(error)
      })
  }
}
