<template>
  <a
    :href="productData.url"
    class="card px-1 py-1"
    :data-bingads-suffix="productData.adPingSuffix"
  >
    <!--    <div class="card-image" :style="backgroundColor">-->
    <div class="card-image">
      <img
        :src="
          device.isAndroid
            ? proxyImage(productData.thumbnailUrl)
            : productData.thumbnailUrl
        "
        :alt="productData.name"
      />
    </div>
    <div class="card-content mt-1 px-1 pt-1 has-text-left">
      <a
        :href="productData.url"
        class="has-text-primary-dark is-uppercase has-text-weight-semibold is-three-lines is-size-7"
        :data-bingads-suffix="productData.adPingSuffix"
      >
        {{ productData.name }}
      </a>
      <div
        class="product-data pb-1"
        :class="{
          'mt-1': !device.isDesktop,
        }"
      >
        <p
          class="is-size-7 is-size-7-touch has-text-grey is-one-line is-font-family-secondary"
        >
          {{ productData.sellerName }}
        </p>
        <p class="is-size-6 is-size-6-touch is-font-family-secondary py-1">
          <span
            v-if="productData.lowPrice"
            class="has-text-secondary-dark has-text-weight-semibold"
            >{{ formatPrice(productData.lowPrice)
            }}{{ productData.currency }} &nbsp;</span
          >
          <span
            :class="{
              'has-strikethrough-text has-text-grey': productData.lowPrice,
              'has-text-weight-semibold': !productData.lowPrice,
            }"
            >{{ formatPrice(productData.price)
            }}{{ productData.currency }}</span
          >
        </p>
        <p
          v-if="productData.rating === null"
          class="is-size-7 is-size-7-touch has-text-grey is-font-family-secondary"
        >
          {{ productData.shippingInfo }}
        </p>
        <StarRating v-if="productData.rating !== null" :config="starsConfig" />
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import StarRating from '../StarRating.vue'
import { ProductAd } from '~/model/ProductAd'

export default defineComponent({
  name: 'ProductSliderItem',

  components: {
    StarRating,
  },

  props: {
    productData: {
      type: Object as PropType<ProductAd>,
      default() {
        return null
      },
    },
  },

  computed: {
    backgroundColor(): Object {
      return {
        'background-color': `#${this.productData.backgroundColor}` ?? '#ffffff',
      }
    },
    starsConfig(): Object | null {
      if (this.productData.rating) {
        return {
          rating: this.productData.rating / 2,
          style: {
            fullStarColor: '#EBA243',
            emptyStarColor: '#FFF4CD',
            starWidth: 15,
            starHeight: 15,
          },
        }
      }

      return null
    },
  },

  methods: {
    formatPrice(value: number): string {
      const val = value.toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
})
</script>

<style scoped lang="scss">
.card {
  box-shadow: none;

  .card-image {
    text-align: center;
    height: 8rem;

    img {
      height: 8rem;
    }
  }

  .card-content {
    padding-bottom: 0px;
    .is-three-lines {
      height: 3.5rem;
    }

    .has-strikethrough-text {
      font-size: 0.8rem;
    }

    .product-data {
      height: 4.5rem;
    }
  }
}
</style>
