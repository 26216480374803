import { useQueryStore } from '~/store/query'
import { useMainStore } from '~/store/index'

/* function setCookie(name: string, value: string, days: number, domain: string) {
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};expires=${expirationDate.toUTCString()};domain=${domain};path=/;SameSite=None`;
}

function setUserkeyCookie(userkey: string) {
  const config = useRuntimeConfig()
  const url = new URL(config.public.domain)

  setCookie(
    'userkey',
    userkey,
    730,
    config.public.isDev ? config.public.domain : `*.${url.hostname}`
  )
} */

export async function getUser(source: string) {
  const userkey = useCookie('userkey')?.value

  if (!userkey) {
    const mainStore = useMainStore()
    mainStore.clearLocalStorage = true
    mainStore.displayTribeWelcome = true
    return await $fetch('/api/users/create', {
      method: 'POST',
      body: {
        source,
      },
    })
  }

  return await $fetch(`/api/users/${userkey}`)
}

export async function postUserStats() {
  const mainStore = useMainStore()

  if (!mainStore.userApiError) {
    const queryStore = useQueryStore()
    let syncChosenProject = false
    if (!localStorage.getItem('synced_chosen_project')) {
      syncChosenProject = true
    }

    try {
      await $fetch('/api/message/user-stats', {
        method: 'POST',
        body: {
          userkey: queryStore.userInfo.userkey,
          secret: queryStore.secret,
          syncChosenProject,
        },
      })

      localStorage.setItem('synced_chosen_project', true)
    } catch (e) {}
  }
}
