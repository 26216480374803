import { defineStore } from 'pinia'

export const useQueryStore = defineStore('query', {
  persist: {
    storage: 'cookie',
    paths: ['userInfo', 'secret'],
  },
  state: () => ({
    q: '',
    action: 'web',
    filterDate: 'All',
    filterMarket: 'fr-FR',
    filterSize: 'All',
    currentPageNumber: 1,
    headers: [],
    suggestions: null as [] | null,
    requestGUID: null as string | null,
    pageLoadPingUrl: null as string | null,
    pingUrlBase: null as string | null,
    userInfo: {},
    suggestTravelAd: false,
    userkey: null as string | null,
    secret: null as string | null,
    baui: null as string | null,
    muid: null as string | null,
  }),
  actions: {
    update(value: string) {
      this.q = value
    },
    actionUpdate(value: string) {
      this.action = value
    },
    headersUpdate(value: []) {
      this.headers = value
    },
    setDate(value: string) {
      this.filterDate = value
    },
    setMarket(value: string) {
      this.filterMarket = value
    },
    setSize(value: string) {
      this.filterSize = value
    },
    setSuggestions(value: [] | null) {
      this.suggestions = value
    },
    resetSuggestions() {
      this.suggestions = null
    },
    setRequestGUID(value: string) {
      this.requestGUID = value
    },
    setPageLoadPingUrl(value: string) {
      this.pageLoadPingUrl = value
    },
    setPingUrlBase(value: string) {
      this.pingUrlBase = value
    },
    setSuggestTravelAd(value: boolean) {
      this.suggestTravelAd = value
    },
    setUserkey(value: string) {
      this.userkey = value
    },
    setUserInfo(value: any) {
      this.userInfo = {
        userkey: value?.userkey,
        connected: value?.connected,
        dropsCount: Number((value?.dropsCount || value?.total_drops) ?? 0),
        homePreferences: value?.home_preferences,
        searchPreferences: value?.search_preferences,
        tribe: value?.tribe,
      }
    },
  },
})
