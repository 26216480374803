<template>
  <Modal
    classes="lilo-modal"
    :show="true"
    mask-class="lilo-news-feed-report-modal"
  >
    <template #header>
      <h4
        v-if="reportSent"
        class="is-size-4 has-text-weight-semibold has-text-primary has-text-centered"
      >
        {{ translate('home.news_feed.report.thank_you') }}
      </h4>
      <h4
        v-if="!reportSent"
        class="is-size-4 has-text-weight-semibold has-text-primary has-text-centered"
      >
        {{ translate('home.news_feed.report.report_this_article') }}
      </h4>
      <img
        class="close"
        src="@/assets/images/icons/cross-dark.svg"
        width="12"
        alt="plus"
        @click="$emit('closeNewsFeedReportModal', false)"
      />
    </template>
    <template #body>
      <div v-if="reportSent" class="p-3">
        <p>
          {{ translate('home.news_feed.report.disclaimer') }}
        </p>
        <p class="my-2 has-text-weight-semibold">
          {{ translate('home.news_feed.report.delete') }} {{ mediaName }}
          {{ translate('home.news_feed.report.from_feed') }}
        </p>
        <p>
          {{ translate('home.news_feed.report.you_can_configure') }}
        </p>
      </div>
      <div v-if="!reportSent" class="p-3">
        <p>
          {{ translate('home.news_feed.report.you_are_about_to_report') }}
          <span class="has-text-weight-semibold"
            >{{ mediaName }} : {{ articleName }}</span
          >.
        </p>
        <p class="my-2">{{ translate('home.news_feed.report.tell_us_why') }}</p>
        <textarea class="textarea" rows="5"></textarea>
      </div>
    </template>
    <template #footer>
      <div
        class="is-flex is-justify-content-center"
        :class="{
          'is-flex-direction-column-reverse': !device.isDesktopOrTablet,
        }"
      >
        <button
          class="lilo-button-grey mr-2"
          :class="{
            'mt-2': !device.isDesktopOrTablet,
          }"
          @click="$emit('closeNewsFeedReportModal', false)"
        >
          {{ translate('home.news_feed.report.cancel') }}
        </button>
        <button
          v-if="reportSent"
          class="lilo-button-primary"
          @click="$emit('closeNewsFeedReportModal', true)"
        >
          {{ translate('home.news_feed.report.configure') }}
        </button>
        <button
          v-if="!reportSent"
          class="lilo-button-primary"
          @click="reportSent = true"
        >
          {{ translate('home.news_feed.report.submit') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const reportSent = ref(false)
defineEmits(['closeNewsFeedReportModal'])

defineProps({
  articleName: {
    type: String,
    default: '',
  },
  mediaName: {
    type: String,
    default: '',
  },
})
</script>

<style lang="scss">
.lilo-news-feed-report-modal {
  p {
    font-weight: 500;
  }
}
</style>
