<template>
  <div class="place-list-item">
    <div class="media">
      <div class="media-content">
        <h5 class="has-text-primary has-text-weight-semibold">
          {{ place.name }}
        </h5>
        <div class="is-font-family-secondary has-text-grey-dark is-size-7">
          <div v-if="place.rating" class="is-flex">
            <StarRating :config="starsConfig" class="mr-1" />
            <span>({{ place.ratingCount }})</span>
          </div>
          <p class="is-one-line">
            {{ place.address }}
          </p>
          <p v-if="place.schedule">
            <span
              class="has-text-weight-semibold"
              :class="{
                'has-text-primary': schedule.isOpen,
                'has-text-danger': !schedule.isOpen,
              }"
            >
              {{
                schedule.isOpen
                  ? translate('places.item.open')
                  : translate('places.item.closed')
              }}
            </span>
            <span
              class="has-vertical-line-before"
              :class="{
                'has-text-danger': schedule.isClosing,
              }"
            >
              {{ schedule.nextTransition }}
            </span>
          </p>
        </div>
      </div>
      <figure class="media-right">
        <p v-if="place.imageUrl" class="image is-96x96">
          <img
            :src="
              device.isAndroid ? proxyImage(place.imageUrl) : place.imageUrl
            "
            :alt="place.name"
          />
        </p>
      </figure>
    </div>
    <div class="mt-1 buttons">
      <a
        v-if="place.phoneNumber && device.isMobile"
        class="lilo-button px-4"
        :href="'tel:' + place.phoneNumber"
      >
        <Icon name="mdi:phone" />
        <span>{{ translate('places.item.call') }}</span>
      </a>
      <p
        v-if="place.phoneNumber && device.isDesktopOrTablet"
        class="has-text-primary has-text-weight-semibold mr-2"
      >
        <Icon name="mdi:phone" size="20px" />
        <span>{{ place.phoneNumber }}</span>
      </p>
      <a
        v-if="place.website"
        class="lilo-button px-4"
        :href="place.website"
        target="_blank"
      >
        <span class="icon">
          <img src="@/assets/images/icons/earth.svg" alt="earth icon" />
        </span>
        <span>{{ translate('places.item.website') }}</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { DateTime } from 'luxon'
import OpeningHours from 'opening_hours'
import StarRating from '../StarRating.vue'
import { PlaceResult } from '~/model/PlaceResult'

export default defineComponent({
  name: 'PlaceListItem',

  components: {
    StarRating,
  },

  props: {
    place: {
      type: Object as PropType<PlaceResult>,
      default() {
        return null
      },
    },
  },

  computed: {
    schedule(): Object | null {
      if (this.place.schedule) {
        const openingHours = new OpeningHours(this.place.schedule)
        const nextTransition = DateTime.fromJSDate(openingHours.getNextChange())

        return {
          isOpen: openingHours.getState(),
          isClosing:
            openingHours.getState() &&
            Math.abs(
              nextTransition.diff(DateTime.now(), 'hours').as('hours'),
            ) <= 2,
          nextTransition:
            (openingHours.getState()
              ? // @ts-expect-error
                this.translate('places.item.closing')
              : // @ts-expect-error
                this.translate('places.item.opening')) +
            ' ' +
            nextTransition
              .setLocale(this.mainStore.locale)
              .toLocaleString(DateTime.TIME_SIMPLE),
        }
      }

      return null
    },

    starsConfig(): Object | null {
      if (this.place.rating) {
        return {
          rating: this.place.rating / 2,
          style: {
            fullStarColor: '#EBA243',
            emptyStarColor: '#FFF4CD',
            starWidth: 12,
            starHeight: 12,
          },
        }
      }

      return null
    },
  },
})
</script>

<style scoped lang="scss">
h5 {
  font-size: 1rem;
}

p {
  font-size: 0.85rem;
}

.image {
  max-height: 5rem;
  overflow-y: hidden;
}
.buttons {
  .lilo-button {
    font-size: 0.85rem !important;
  }
}

.has-vertical-line-before:before {
  content: ' | ';
  color: #4a4a4a;
}
</style>
