import { useQueryStore } from '~/store/query'

const MAX_BOOKMARKS = 16
const PLACEHOLDER_BOOKMARK_ID = 'addBookmarkPlaceholder'
const PLACEHOLDER_BOOKMARK_OBJECT = {
  id: PLACEHOLDER_BOOKMARK_ID,
}

export async function getBookmarks() {
  const queryStore = useQueryStore()

  try {
    const { data = [], refresh } = await useFetch(
      `/api/users/${queryStore?.userInfo?.userkey}/bookmarks`,
    )

    !data.value && (await refresh())
    const bookmarks = data?.value || []

    if (bookmarks.length < MAX_BOOKMARKS) {
      bookmarks.push(PLACEHOLDER_BOOKMARK_OBJECT)
    }

    localStorage.setItem('bookmarks', JSON.stringify(bookmarks))

    return bookmarks
  } catch (error) {
    return []
  }
}

export async function postBookmark(url: string) {
  const queryStore = useQueryStore()

  try {
    const { data = {} } = await useFetch(
      `/api/users/${queryStore?.userInfo?.userkey}/bookmark`,
      {
        method: 'POST',
        body: JSON.stringify({ url }),
      },
    )

    if (!data?.value) {
      throw new Error('Failed to add bookmark')
    }

    let bookmarks = JSON.parse(localStorage.getItem('bookmarks'))
    if (!bookmarks) {
      bookmarks = [PLACEHOLDER_BOOKMARK_OBJECT]
    } else if (bookmarks.length >= MAX_BOOKMARKS) {
      bookmarks = bookmarks.filter(
        (bookmark) => bookmark.id !== PLACEHOLDER_BOOKMARK_ID,
      )
    }

    const newBookmarks = [data.value, ...bookmarks]
    localStorage.setItem('bookmarks', JSON.stringify(newBookmarks))
  } catch (error) {
    console.log(error.message)
  }
}

export async function deleteBookmark(bookmarkId: string) {
  const queryStore = useQueryStore()

  await useFetch(`/api/users/${queryStore?.userInfo?.userkey}/bookmark`, {
    method: 'DELETE',
    body: JSON.stringify({ bookmarkId }),
  }).catch(() => [])

  const bookmarks = JSON.parse(localStorage.getItem('bookmarks'))
  const newBookmarks = bookmarks.filter(
    (bookmark) => bookmark.id !== bookmarkId,
  )

  if (
    newBookmarks.length < MAX_BOOKMARKS &&
    !newBookmarks.find((bookmark) => bookmark.id === PLACEHOLDER_BOOKMARK_ID)
  ) {
    newBookmarks.push(PLACEHOLDER_BOOKMARK_OBJECT)
  }

  localStorage.setItem('bookmarks', JSON.stringify(newBookmarks))
}
