<template>
  <a :href="videoData.contentUrl" class="card px-1 py-1">
    <div class="card-image">
      <figure class="image is-3by2">
        <img :src="videoData.thumbnailUrl" :alt="videoData.name" />
        <span class="tag is-dark">
          {{ readableDuration }}
        </span>
      </figure>
    </div>
    <div class="card-content mt-1 px-1 pt-1 has-text-left">
      <p
        v-if="videoData.publisherName"
        class="has-text-grey-dark is-size-7 mb-2 is-font-family-secondary"
      >
        {{ videoData.publisherName }}
      </p>
      <p
        class="has-text-primary has-text-weight-semibold is-three-lines is-size-6 mb-3"
      >
        {{ videoData.name }}
      </p>
      <p class="has-text-grey is-size-7 is-font-family-secondary">
        {{ getTimeSince(videoData.datePublished, mainStore.locale) }}
      </p>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VideoItemMixin from './VideoItemMixin'

export default defineComponent({
  name: 'VideoSliderItem',

  mixins: [VideoItemMixin],
})
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';

.card {
  box-shadow: none;
  height: 16rem;
  @media screen and (min-width: $x-small) {
    height: 17rem;
  }
  @media screen and (min-width: $medium) {
    height: 18rem;
  }

  .card-image {
    img {
      object-fit: cover;
    }
    .tag {
      position: absolute;
      bottom: 0.4rem;
      right: 0.4rem;
      opacity: 85%;
    }
  }

  .card-content {
    padding-bottom: 0px;

    .is-three-lines {
      height: 4.5rem;
    }
  }
}
</style>
