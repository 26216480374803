import * as Sentry from '@sentry/vue'

async function lazyLoadSentryIntegrations() {
  const { Replay } = await import('@sentry/vue')
  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  )
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig()

  // don't load on server
  if (!process.client) {
    return
  }

  if (!config.public.sentry) {
    return
  }

  try {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentry.dsn,
      environment: config.public.sentry.environment,
      integrations: [
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],

      tracesSampleRate: 0.001, // Change in prod

      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.1,

      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        'error loading dynamically imported module',
        'Importing a module script failed.',
        "Can't find variable: listenToMutationsFromJS",
        'Cannot find module',
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    })

    lazyLoadSentryIntegrations()
  } catch (e) {
    console.log('Sentry integration failed')
  }
})
