import { VideoResult } from '~/model/VideoResult'

export class VideoSearchResponse {
  videoResults: Array<VideoResult>

  constructor(videoResults: Array<VideoResult>) {
    this.videoResults = videoResults
  }

  toJSON() {
    return { ...this }
  }
}
