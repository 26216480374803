<template>
  <div
    :class="{
      'mb-6': device.isDesktop,
      'mb-1 p-3 has-background-white': !device.isDesktop,
    }"
  >
    <SectionTitle :title="translate('section_title.images')" />
    <LiloCarousel :slides-per-page="device.isMobile ? 2 : 5">
      <template #slides>
        <Slide
          v-for="(item, index) in imageResults"
          :key="'search-image-slide-' + index"
        >
          <ImageSliderItem
            :key="'search-image-slide-content' + index"
            :image-data="item"
            class="slide"
            :index="index"
          />
        </Slide>
      </template>
    </LiloCarousel>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Slide } from 'vue3-carousel'
import ImageSliderItem from './ImageSliderItem.vue'
import LiloCarousel from '~/components/LiloCarousel.vue'
import SectionTitle from '~/components/web/SectionTitle.vue'
import { ImageResult } from '~/model/ImageResult'

export default defineComponent({
  name: 'ImageResults',

  components: {
    LiloCarousel,
    ImageSliderItem,
    SectionTitle,
    Slide,
  },

  props: {
    imageResults: {
      type: Array as PropType<Array<ImageResult>>,
      default() {
        return []
      },
    },
  },
})
</script>
