<template>
  <div
    :class="{
      'mb-6': device.isDesktop,
      'mb-1 p-3 has-background-white': !device.isDesktop,
    }"
  >
    <SectionTitle :title="translate('section_title.news')" />
    <div v-if="device.isDesktop" class="columns is-multiline">
      <NewsGridItem
        v-for="(item, index) in newsGridData"
        :key="'news-result-' + index"
        :news-data="item"
        class="column is-half mb-2"
      />
    </div>
    <div v-if="!device.isDesktop" class="mt-3">
      <LiloCarousel :slides-per-page="device.isTablet ? 4 : 2.2">
        <template #slides>
          <Slide
            v-for="(item, index) in newsResults"
            :key="'news-result-slide' + index"
          >
            <NewsSliderItem
              :key="'news-result-' + index"
              :news-data="item"
              class="slide"
              :index="index"
            />
          </Slide>
        </template>
      </LiloCarousel>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Slide } from 'vue3-carousel'
import NewsGridItem from './NewsGridItem.vue'
import NewsSliderItem from './NewsSliderItem.vue'
import LiloCarousel from '~/components/LiloCarousel.vue'
import SectionTitle from '~/components/web/SectionTitle.vue'
import { NewsResult } from '~/model/NewsResult'

export default defineComponent({
  name: 'NewsResults',

  components: {
    LiloCarousel,
    NewsGridItem,
    NewsSliderItem,
    SectionTitle,
    Slide,
  },

  props: {
    newsResults: {
      type: Array as PropType<Array<NewsResult>>,
      default() {
        return []
      },
    },
  },

  computed: {
    newsGridData(): Array<NewsResult> {
      const gridData: Array<NewsResult> = this.newsResults

      if (gridData.length > 4) {
        gridData.length = 4
      }

      return gridData
    },
  },
})
</script>
