<template>
  <Teleport :to="to">
    <Transition name="modal-fade">
      <div
        v-if="show"
        class="modal-mask"
        :class="maskClass"
        :style="positionModal"
      >
        <div class="modal-container" :class="classes">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    show: Boolean,
    to: {
      type: String,
      default: 'body',
    },
    bodyClasses: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    maskClass: {
      type: String,
      default: '',
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      positionModal: {},
    }
  },

  updated() {
    this.positionModal = {
      ...(this.x > 0 && { left: this.x + 'px' }),
      ...(this.y > 0 && { top: this.y + 'px' }),
    }
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/utils/variables.scss';

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.lilo-modal,
.lilo-modal-large {
  position: relative;
  left: calc((100vw - 100%) / 2);
  border-radius: 20px;
  width: calc(100vw - 10px);
}

.lilo-modal {
  @media screen and (min-width: 510px) {
    width: 500px;
  }
}

.lilo-modal-large {
  @media screen and (min-width: 510px) {
    width: 800px;
  }
}

.modal-container {
  margin: auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 80%;
  overflow-y: scroll;
}

.modal-header {
  position: relative;
}

.modal-body,
.modal-header,
.modal-footer {
  padding: 1.5rem;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  background-color: $white;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}
</style>
