<template>
  <Modal
    :show="show"
    classes="modal-bookmark-menu"
    mask-class="no-overlay"
    :x="x"
    :y="y"
  >
    <template #body>
      <div class="bookmark__menu">
        <a :href="url" target="_blank">{{
          translate('modal.bookmark.menu.open')
        }}</a>
        <div @click="removeBookmark">
          {{ translate('modal.bookmark.menu.delete') }}
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { deleteBookmark } from '~/services/bookmarkClient'

const props = defineProps({
  show: Boolean,
  bookmarkId: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits([
  'close-modal-bookmark-menu',
  'delete-bookmark-loading-start',
  'delete-bookmark-loading-end',
  'update-bookmarks',
])

async function removeBookmark() {
  emit('delete-bookmark-loading-start')
  await deleteBookmark(props.bookmarkId)
  emit('close-modal-bookmark-menu')
  emit('delete-bookmark-loading-end')
  emit('update-bookmarks')
}
</script>

<style lang="scss">
@import '~/assets/scss/utils/variables.scss';

.modal-bookmark-menu {
  border-radius: 12px;
  .modal-footer,
  .modal-header {
    display: none;
  }
  .modal-body {
    padding: 0;
  }
}

.bookmark__menu {
  padding: 15px;

  > a,
  div {
    color: $grey-dark;
    cursor: pointer;

    &:hover {
      background-color: $grey-light;
      transition: background-color 250ms linear;
    }
  }
}
</style>
