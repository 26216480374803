<template>
  <div class="container">
    <div class="columns">
      <div class="column is-half-desktop is-full-touch p-6">
        <a
          id="logo"
          href="https://search.lilo.org"
          class="column p-0 is-1 mb-6"
        >
          <img
            src="@/assets/images/logo-baseline.png"
            alt="Link to search engine home"
          />
        </a>
        <div>
          <h4 class="is-size-4 has-text-primary has-text-weight-bold">
            {{ translate('error.no_service.title') }}
          </h4>
          <p
            class="is-font-family-secondary mt-5"
            v-html="translate('error.no_service.description')"
          ></p>
          <h4 class="is-size-4 has-text-primary has-text-weight-bold mt-5">
            {{ translate('error.no_service.title', 'en') }}
          </h4>
          <p
            class="is-font-family-secondary mt-5"
            v-html="translate('error.no_service.description', 'en')"
          ></p>
        </div>
        <div id="actions" class="mt-6">
          <a
            class="has-text-weight-semibold"
            href="https://www.lilo.org/projets"
          >
            {{ translate('error.action.projects') }}
          </a>
          <a class="has-text-weight-semibold" href="https://support.lilo.org">
            {{ translate('error.action.support') }}
          </a>
        </div>
      </div>
      <div class="column is-half is-hidden-touch">
        <img
          class="astronaut"
          src="@/assets/images/error-astronaut.svg"
          alt="error image"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'NoServicePage',

  head() {
    return {
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
      script: [
        {
          src: 'https://render.lilo.org/js/telemetry.js',
        },
      ],
    }
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';
@import '~/assets/scss/layouts/error.scss';
</style>
