<template>
  <div
    class="has-background-light-blue"
    :class="{
      'level py-3': device.isDesktop,
      'pl-5 py-2': !device.isDesktop,
    }"
  >
    <div
      v-if="device.isDesktop"
      :class="{
        'level-left': device.isDesktop,
      }"
    >
      <div class="level-item is-inline-flex">
        <p class="has-text-weight-semibold is-size-6 is-align-items-baseline">
          <span class="has-text-weight-semibold"
            >{{ translate('filters.region.title') }} :
          </span>
          <span class="lilo-select">
            <v-select
              v-model="market"
              :reduce="(option) => option.code"
              :options="marketOptions"
              :clearable="false"
            />
          </span>
        </p>
      </div>
      <div
        v-if="queryStore.action !== 'news'"
        class="level-item is-inline-flex"
      >
        <p class="has-text-weight-semibold is-size-6 is-align-items-baseline">
          <span class="has-text-weight-semibold"
            >{{ translate('filters.date.title') }} :</span
          >
          <span class="lilo-select">
            <v-select
              v-model="date"
              :reduce="(option) => option.code"
              :options="dateOptions"
              :clearable="date !== 'All'"
            />
          </span>
        </p>
      </div>
      <div
        v-if="queryStore.action === 'image'"
        class="level-item is-inline-flex"
      >
        <p class="has-text-weight-semibold is-size-6 is-align-items-baseline">
          <span class="has-text-weight-semibold"
            >{{ translate('filters.size.title') }} :</span
          >
          <span class="lilo-select">
            <v-select
              v-model="size"
              :reduce="(option) => option.code"
              :options="sizeOptions"
              :clearable="size !== 'All'"
            />
          </span>
        </p>
      </div>
    </div>
    <div v-if="device.isDesktop" class="level-right pr-6">
      <button
        class="lilo-button-dark-outline is-small is-rounded"
        @click="hideFilters"
      >
        <img
          src="@/assets/images/icons/cross.svg"
          alt="cross icon"
          width="12"
        />
      </button>
    </div>
    <div v-if="!device.isDesktop" class="is-flex has-min-width">
      <div class="is-inline-flex is-align-items-baseline">
        <p class="has-text-weight-semibold is-size-7">
          {{ translate('filters.region.title') }}
        </p>
        <select v-model="market" class="select is-size-7 ml-1">
          <option
            v-for="(option, optionIndex) in marketOptions"
            :key="'search-filter-size-option-' + optionIndex"
            :value="option.code"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <select v-model="date" class="select is-size-7 ml-3">
        <option
          v-for="(option, optionIndex) in dateOptions"
          :key="'search-filter-date-option-' + optionIndex"
          :value="option.code"
        >
          {{ option.label }}
        </option>
      </select>
      <select
        v-if="queryStore.action === 'image'"
        v-model="size"
        class="select is-size-7 ml-3"
      >
        <option
          v-for="(option, optionIndex) in sizeOptions"
          :key="'search-filter-size-option-' + optionIndex"
          :value="option.code"
        >
          {{ option.label }}
        </option>
      </select>
      <div class="mx-4">
        <button
          class="lilo-button-dark-outline is-small is-rounded"
          @click="hideFilters"
        >
          <img
            src="@/assets/images/icons/cross.svg"
            alt="cross icon"
            width="12"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchFilters',

  emits: ['hideFilters'],

  data() {
    return {
      date: '',
      market: '',
      size: '',
    }
  },

  computed: {
    dateOptions(): { label: string; code: string }[] {
      return [
        {
          label: this.translate('filters.date.options.all_time'),
          code: 'All',
        },
        {
          label: this.translate('filters.date.options.day'),
          code: 'Day',
        },
        {
          label: this.translate('filters.date.options.week'),
          code: 'Week',
        },
        {
          label: this.translate('filters.date.options.month'),
          code: 'Month',
        },
      ]
    },
    marketOptions() {
      return [
        {
          label: this.translate('filters.region.options.de-DE'),
          code: 'de-DE',
        },
        {
          label: this.translate('filters.region.options.fr-FR'),
          code: 'fr-FR',
        },
        {
          label: this.translate('filters.region.options.fr-BE'),
          code: 'fr-BE',
        },
        {
          label: this.translate('filters.region.options.fr-CA'),
          code: 'fr-CA',
        },
        {
          label: this.translate('filters.region.options.fr-CH'),
          code: 'fr-CH',
        },
        {
          label: this.translate('filters.region.options.es-ES'),
          code: 'es-ES',
        },
        {
          label: this.translate('filters.region.options.en-US'),
          code: 'en-US',
        },
        {
          label: this.translate('filters.region.options.it-IT'),
          code: 'it-IT',
        },
        {
          label: this.translate('filters.region.options.en-GB'),
          code: 'en-GB',
        },
        {
          label: this.translate('filters.region.options.pt-PT'),
          code: 'pt-PT',
        },
      ]
    },
    sizeOptions() {
      return [
        {
          label: this.translate('filters.size.options.all_sizes'),
          code: 'All',
        },
        {
          label: this.translate('filters.size.options.small'),
          code: 'Small',
        },
        {
          label: this.translate('filters.size.options.medium'),
          code: 'Medium',
        },
        {
          label: this.translate('filters.size.options.large'),
          code: 'Large',
        },
        {
          label: this.translate('filters.size.options.wallpaper'),
          code: 'Wallpaper',
        },
      ]
    },
  },

  watch: {
    date: function (val: string, oldVal: string) {
      if (val === oldVal || !oldVal) {
        return
      }
      updateParamAndRedirect(val, 'date')
    },
    market: function (val: string, oldVal: string) {
      if (val === oldVal || !oldVal) {
        return
      }
      updateParamAndRedirect(val, 'market')
    },
    size: function (val: string, oldVal: string) {
      if (val === oldVal || !oldVal) {
        return
      }
      updateParamAndRedirect(val, 'size')
    },
  },

  created() {
    this.date = this.queryStore.filterDate
    this.market = this.queryStore.filterMarket
    this.size = this.queryStore.filterSize
  },

  methods: {
    hideFilters(): void {
      this.$emit('hideFilters')
    },
  },
})

function updateParamAndRedirect(val: string, paramName: string) {
  const params = new URLSearchParams(window.location.search)
  if (params.has(paramName)) {
    params.delete(paramName)
  }

  params.append(paramName, val)
  window.location.href = '?' + params.toString()
}
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';

span.lilo-select {
  display: inline-block;
}

.level {
  padding-left: 9.11%;
}

@media screen and (min-width: $large) {
  .level-item {
    margin-right: 1.5rem !important;
  }
}

@media screen and (max-width: $large - 1) {
  .has-background-light-blue {
    overflow-x: scroll;
    -ms-overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    select {
      min-width: max-content;
    }

    .select {
      border: none;
      border-radius: 0.5rem;
      padding-left: 0.3rem;
      background-color: white;
    }
  }

  p.has-text-weight-semibold.is-size-7 {
    min-width: max-content;
  }

  .has-min-width {
    min-width: max-content;
  }
}
</style>
