import { NewsResult } from '~/model/NewsResult'
import { NewsSearchResponse } from '~/model/NewsSearchResponse'

export function parseNewsResponse(data: any): NewsSearchResponse {
  const news: Array<NewsResult> = []

  for (const index in data.articles) {
    const result = data.articles[index]

    news.push(
      new NewsResult(
        result.publishedAt,
        result.image,
        null,
        result.source.name,
        result.title,
        result.image,
        result.url,
        result.description,
      ),
    )
  }

  return new NewsSearchResponse(news)
}

export class GnewsioClient {
  async fetchNews(queryStore: any): Promise<NewsSearchResponse> {
    const config = useRuntimeConfig()
    const lang = queryStore.filterMarket.substr(0, 2)

    let sanitizedQuery = queryStore.q.replace(/['"]+/g, ' ')
    sanitizedQuery = sanitizedQuery.replace(/\s+/g, ' ').trim()

    return await $fetch('https://gnews.io/api/v4/search', {
      params: {
        lang,
        country: ['fr-CA', 'fr-CH', 'en-US', 'en-GB'].includes(
          queryStore.filterMarket,
        )
          ? queryStore.filterMarket.substr(3, 2).toLowerCase()
          : lang,
        apikey: config.gnewsApiKey,
        q: `${sanitizedQuery}`,
        max: 50,
        in: 'title,description,content',
      },
    })
      .then(function (res: { data: any }) {
        return parseNewsResponse(res)
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          return parseNewsResponse({ articles: [] })
        }
        throw new Error(error)
      })
  }
}
