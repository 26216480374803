<template>
  <div class="card px-1 py-1">
    <a :href="newsData.url" class="card-image">
      <figure class="image is-3by2">
        <img
          v-if="newsData.thumbnailUrl !== undefined"
          :src="
            device.isAndroid
              ? proxyImage(newsData.thumbnailUrl)
              : newsData.thumbnailUrl
          "
          alt="news image"
        />
        <img
          v-else
          src="@/assets/images/placeholder-3by2.svg"
          alt="news image placeholder"
        />
      </figure>
    </a>
    <div class="card-content mt-1 px-1 pt-1 has-text-left">
      <a :href="newsData.url" class="is-flex mb-2">
        <img
          v-if="newsData.providerLogoUrl"
          :src="
            device.isAndroid
              ? proxyImage(newsData.providerLogoUrl)
              : newsData.providerLogoUrl
          "
          class="image is-24x24 is-flex mr-2 has-rounded-corners"
          alt="news image"
        />
        <span
          :v-if="newsData.providerName"
          class="is-capitalized has-text-grey-dark is-size-7 pt-1 is-one-line is-font-family-secondary"
        >
          {{ newsData.providerName }}
        </span>
      </a>
      <a
        :href="newsData.url"
        class="has-text-primary has-text-weight-semibold is-three-lines is-size-6 mb-3"
      >
        {{ newsData.title }}
      </a>
      <a
        :href="newsData.url"
        class="mt-1 has-text-grey is-size-7 is-font-family-secondary"
      >
        {{ getTimeSince(newsData.datePublished, mainStore.locale) }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NewsItemMixin from './NewsItemMixin'

export default defineComponent({
  name: 'NewsSliderItem',

  mixins: [NewsItemMixin],
})
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';

.card {
  box-shadow: none;
  height: 16rem;
  @media screen and (min-width: $x-small) {
    height: 17rem;
  }
  @media screen and (min-width: $medium) {
    height: 18rem;
  }

  .card-image {
    img {
      object-fit: cover;
    }
  }
}
</style>
