<template>
  <Modal :show="show" :classes="'result'">
    <template #header>
      <div v-if="success" class="result__content">
        <div class="result__status">
          {{ translate('modal.result.success.status') }}
        </div>
        <div class="result__sentence">
          {{ translate('modal.result.success.sentence') }}
        </div>
      </div>
      <div v-else class="result__content">
        <div class="result__status">
          {{ translate('modal.result.fail.status') }}
        </div>
        <div class="result__sentence">
          {{ translate('modal.result.fail.sentence') }}
        </div>
      </div>
    </template>
    <template #body>
      <div v-if="success" class="result__content">
        <AnimatedIconSuccess />
      </div>
      <div v-else class="result__content">
        <AnimatedIconFail />
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { AntibotClient } from '~/services/antibotClient'

export default {
  name: 'ModalResult',

  props: {
    show: Boolean,
    success: Boolean,
    domain: {
      type: String,
      default: '',
    },
  },

  emits: ['reopen-modal-antibot', 'close-modal-result'],

  watch: {
    show() {
      if (this.show) {
        if (this.success) {
          const antibotClient = new AntibotClient()
          antibotClient.whitelistIp()
          document.cookie = `TRUST=1; max-age=1209600; path=/; domain=${this.domain}; samesite=None; secure`
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          setTimeout(() => {
            this.$emit('close-modal-result')
            this.$emit('reopen-modal-antibot')
          }, 2000)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';
@import '~/assets/scss/utils/variables.scss';

.result {
  width: 600px;
  height: 385px;
  border-radius: 20px;
  position: relative;

  @media screen and (min-width: $large) {
    height: 335px;
    margin: auto;
  }
}

.result__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result__status {
  color: $primary;
  font-family: $family-sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.result__sentence {
  color: $grey-dark;
  padding: 0 16px 0 16px;
}
</style>
