import { mapStores } from 'pinia'
import { useMainStore } from '~/store/index'
import { useImageStore } from '~/store/image'
import { useQueryStore } from '~/store/query'

const storesMixin = {
  computed: {
    ...mapStores(useMainStore, useImageStore, useQueryStore),
  },
}

export default defineNuxtPlugin(({ vueApp, $pinia }) => {
  vueApp.mixin(storesMixin)
  return {
    provide: {
      store: useMainStore($pinia),
    },
  }
})
