<template>
  <ImagePreview
    v-if="imageStore.imageToPreview && !device.isDesktop"
    :image-result="imageStore.imageToPreview"
  />
  <div v-else>
    <a href="#content" class="skip">
      {{ translate('navigation.skip_to_results') }}
    </a>
    <NavBar
      v-if="device.isDesktopOrTablet"
      :connected="queryStore.userInfo.connected"
      :drops-count="queryStore.userInfo.dropsCount"
      @toggle-filters="showFilters = !showFilters"
    />
    <NavBarMobile
      v-else
      :connected="queryStore.userInfo.connected"
      :drops-count="queryStore.userInfo.dropsCount"
      @toggle-filters="showFilters = !showFilters"
    />
    <ReactApps :search-result-page="true" />
    <div v-if="queryStore.action !== 'video'">
      <Transition name="slide-fade">
        <SearchFilters v-if="showFilters" @hide-filters="showFilters = false" />
      </Transition>
    </div>
    <div
      id="content"
      :class="{
        container:
          !device.isTablet &&
          (queryStore.action !== 'image' ||
            (queryStore.action === 'image' &&
              (!imageStore.imageResponse ||
                imageStore.imageResponse.images.length === 0))),
        'image-search-container':
          queryStore.action === 'image' &&
          imageStore.imageResponse &&
          imageStore.imageResponse.images.length > 0,
      }"
    >
      <ImageSearch v-if="imageStore.imageResponse" />
      <VideoSearch
        v-if="videoResponse && videoResponse.videoResults.length > 0"
        :video-results="videoResponse.videoResults"
      />
      <div v-if="searchResponse && searchResponse.textResults.length > 0">
        <WebSearch
          v-if="queryStore.action === 'web' || !queryStore.action"
          :search-result="searchResponse"
        />
      </div>
      <NewsSearch
        v-if="
          queryStore.action === 'news' && newsResponse.newsResults.length > 0
        "
        :news-results="newsResponse.newsResults"
      />
      <NoResult
        v-if="
          ((queryStore.action === 'web' || !queryStore.action) &&
            (!searchResponse ||
              (searchResponse && searchResponse.textResults.length === 0))) ||
          (queryStore.action === 'news' &&
            newsResponse.newsResults.length === 0) ||
          (queryStore.action === 'video' &&
            (!videoResponse || videoResponse.videoResults.length === 0)) ||
          (queryStore.action === 'image' &&
            (!imageStore.imageResponse ||
              imageStore.imageResponse.images.length === 0))
        "
      />
    </div>

    <LiloFooter />
  </div>
</template>

<script lang="ts">
import LiloFooter from '../components/LiloFooter.vue'
import ImageSearch from '../components/images/ImageSearch.vue'
import ImagePreview from '../components/images/ImagePreview.vue'
import WebSearch from '../components/web/WebSearch.vue'
import VideoSearch from '../components/videos/VideoSearch.vue'
import NoResult from '../components/NoResult.vue'
import SearchFilters from '~/components/SearchFilters.vue'
import NavBarMobile from '~/components/NavBarMobile.vue'
import NavBar from '~/components/NavBar.vue'
import ReactApps from '~/components/ReactApps.vue'
import NewsSearch from '~/components/news/NewsSearch.vue'

import { postUserStats } from '~/services/userClient'

import type { NewsSearchResponse } from '~/model/NewsSearchResponse'
import type { VideoSearchResponse } from '~/model/VideoSearchResponse'
import type { WebSearchResponse } from '~/model/WebSearchResponse'

export default defineComponent({
  name: 'Search',

  components: {
    ImagePreview,
    ImageSearch,
    LiloFooter,
    NavBar,
    NavBarMobile,
    NewsSearch,
    NoResult,
    SearchFilters,
    VideoSearch,
    WebSearch,
    ReactApps,
  },

  props: {
    searchResponse: {
      type: Object as PropType<WebSearchResponse>,
      default() {
        return null
      },
    },
    videoResponse: {
      type: Object as PropType<VideoSearchResponse>,
      default() {
        return null
      },
    },
    newsResponse: {
      type: Object as PropType<NewsSearchResponse>,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      showFilters: false,
    }
  },

  mounted: function () {
    window.setTimeout(() => {
      postUserStats()
    }, 2500)
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';

@media screen and (min-width: $large) {
  .container {
    margin-left: calc(100% / 12);
  }
}

.level {
  img {
    width: 100%;
  }
}

.min-height {
  min-height: 19rem;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.35s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
