<template>
  <v-select-component />
</template>

<script>
import VSelectComponent from 'vue-select'

export default {
  name: 'VSelect',

  components: {
    VSelectComponent,
  },
}
</script>

<style>
@import 'vue-select/dist/vue-select.css';
</style>
