<template>
  <div class="mt-5">
    <LiloCarousel
      v-if="imageResponse.relatedSearches?.length > 0"
      class="mb-6"
      :slides-per-page="
        device.isDesktop
          ? imageResponse.relatedSearches.length > 6
            ? 7
            : imageResponse.relatedSearches.length
          : device.isTablet
            ? imageResponse.relatedSearches.length >= 5
              ? 5
              : imageResponse.relatedSearches.length
            : 2.05
      "
    >
      <template #slides>
        <Slide
          v-for="(item, kindex) in imageResponse.relatedSearches"
          :key="'search-image-related-search-slide' + kindex"
        >
          <RelatedSearch
            :key="'search-image-related-search' + kindex"
            :related-search="item"
            class="slide"
          />
        </Slide>
      </template>
    </LiloCarousel>
    <div v-if="device.isDesktop" class="columns">
      <div
        class="column p-0"
        :class="{
          'is-three-fifths': imageStore.imageToPreview,
        }"
      >
        <div
          class="is-inline-flex"
          :class="{
            'is-justify-content-space-between':
              imageResponse.images?.length >= 5,
            'is-justify-content-start': imageResponse.images?.length < 5,
          }"
        >
          <ImageResult
            v-for="(item, jindex) in imageResponse.images"
            :key="'search-image-' + jindex"
            :image-result="item"
            :index="jindex"
          />
        </div>
      </div>
      <ImagePreview
        v-if="imageStore.imageToPreview"
        class="column two"
        :image-result="imageStore.imageToPreview"
      />
    </div>
    <div v-if="!device.isDesktop" class="columns is-multiline is-mobile p-0">
      <ImageResult
        v-for="(item, lindex) in imageResponse.images"
        :key="'search-image-' + lindex"
        :image-result="item"
        :index="lindex"
      />
    </div>
    <div
      v-if="
        imageResponse.images?.length > 0 && imageResponse.nextOffset && !loading
      "
      class="level"
    >
      <div class="level-item">
        <button class="lilo-button" @click="loadMoreResults">
          {{ translate('pagination.load_more') }}
        </button>
      </div>
    </div>
    <a
      href="https://privacy.microsoft.com/privacystatement"
      class="has-text-grey has-font-secondary is-size-6"
    >
      {{ translate('results.by_microsoft') }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Slide } from 'vue3-carousel'
import ImagePreview from '~/components/images/ImagePreview.vue'
import ImageResult from '~/components/images/ImageResult.vue'
import RelatedSearch from '~/components/images/RelatedSearch.vue'
import LiloCarousel from '~/components/LiloCarousel.vue'
import { BingClient } from '~/services/bingClient'
import { ImageSearchResponse } from '~/model/ImageSearchResponse'

export default defineComponent({
  name: 'ImageSearch',

  components: {
    ImagePreview,
    ImageResult,
    LiloCarousel,
    RelatedSearch,
    Slide,
  },

  data() {
    const imageResponse: ImageSearchResponse = this.imageStore?.imageResponse

    return {
      imageResponse,
      index: 0,
      loading: false,
    }
  },

  created() {
    this.imageResponse = this.imageStore.imageResponse
  },

  methods: {
    async loadMoreResults(): Promise<void> {
      const bingClient = new BingClient()

      const nextImagesResponse = await bingClient.searchMoreImages(
        this.imageStore.imageResponse.nextOffset,
        this.queryStore,
      )

      this.imageStore.appendImages(nextImagesResponse)
    },
  },
})
</script>

<style lang="scss" scoped>
.is-inline-flex {
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
</style>
