<template>
  <a :href="imageData.hostPageUrl">
    <figure class="has-rounded-corners">
      <img :src="imageData.thumbnailUrl" :alt="imageData.name" />
    </figure>
  </a>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import { ImageResult } from '~/model/ImageResult'

export default {
  name: 'ImageSliderItem',

  props: {
    imageData: {
      type: Object as PropType<ImageResult>,
      default() {
        return null
      },
    },
  },
}
</script>

<style scoped lang="scss">
a {
  width: 100%;
}

figure {
  width: 100%;
}

img {
  height: 7rem;
  width: 100%;
  object-fit: cover;
}
</style>
