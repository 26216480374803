export class MultimediaAd {
  adPingSuffix: string
  description: string
  displayUrl: string
  imageUrl: string
  url: string
  title: string
  cta: string

  constructor(
    adPingSuffix: string,
    description: string,
    displayUrl: string,
    imageUrl: string,
    url: string,
    title: string,
    cta: string,
  ) {
    this.adPingSuffix = adPingSuffix
    this.description = description
    this.displayUrl = displayUrl
    this.imageUrl = imageUrl
    this.url = url
    this.title = title
    this.cta = cta
  }
}
