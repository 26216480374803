export default defineNuxtPlugin(() => {
  // don't load on server
  if (!process.client) {
    return
  }

  window.didomiConfig = {
    theme: {
      color: '#03599b', // Principal color used by the SDK
      linkColor: '#03599b',
      font: 'Avenir', // Font used by the SDK
      buttons: {
        regularButtons: {
          // Learn more/disagree/disagree to all buttons.
          backgroundColor: '#eeeeee',
          textColor: '#999999',
          borderWidth: '0px',
          borderRadius: '1.5rem',
        },
        highlightButtons: {
          // Agree/save/agree to all buttons.
          backgroundColor: '#03599b',
          textColor: '#ffffff',
          borderWidth: '0px',
          borderRadius: '1.5rem',
        },
      },
    },
    notice: {
      position: 'popup',
    },
  }

  window.gdprAppliesGlobally = false
  ;(function () {
    ;(function (e, r) {
      const t = document.createElement('link')
      t.rel = 'preconnect'
      t.as = 'script'
      const n = document.createElement('link')
      n.rel = 'dns-prefetch'
      n.as = 'script'
      const i = document.createElement('link')
      i.rel = 'preload'
      i.as = 'script'
      const o = document.createElement('script')
      o.id = 'spcloader'
      o.type = 'text/javascript'
      o.async = true
      o.charset = 'utf-8'
      let a =
        'https://sdk.privacy-center.org/' +
        e +
        '/loader.js?target_type=notice&target=' +
        r
      if (window.didomiConfig && window.didomiConfig.user) {
        const c = window.didomiConfig.user
        const d = c.country
        const s = c.region
        if (d) {
          a = a + '&country=' + d
          if (s) {
            a = a + '&region=' + s
          }
        }
      }
      t.href = 'https://sdk.privacy-center.org/'
      n.href = 'https://sdk.privacy-center.org/'
      i.href = a
      o.src = a
      const l = document.getElementsByTagName('script')[0]
      l.parentNode.insertBefore(t, l)
      l.parentNode.insertBefore(n, l)
      l.parentNode.insertBefore(i, l)
      l.parentNode.insertBefore(o, l)
    })('321e0288-796c-4afb-9d86-9871b772fd0e', 'rDBVcdaF')
  })()
})
