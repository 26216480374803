<template>
  <div
    class="has-border-light-blue lilo-border-radius"
    :class="{
      'has-background-white pb-2': !device.isDesktop,
      'px-1 pb-3': device.isDesktop,
    }"
  >
    <div class="columns is-mobile">
      <a
        class="column is-align-content-center"
        :href="newsData.url"
        :alt="newsData.title"
        :target="reportEnabled ? '_blank' : '_self'"
      >
        <figure class="image is-4by3">
          <NuxtImg
            v-if="newsData.imageUrl"
            :src="newsData.imageUrl"
            :alt="newsData.title"
            placeholder="/placeholder-1by1.svg"
          />
          <img
            v-else
            src="@/assets/images/placeholder-1by1.svg"
            alt="1by1 icon"
          />
        </figure>
      </a>
      <div class="column is-two-thirds pl-0">
        <div class="is-inline-flex">
          <a
            :href="newsData.url"
            class="has-text-primary is-size-5 is-size-6-touch has-text-weight-medium is-two-lines"
            :target="reportEnabled ? '_blank' : '_self'"
          >
            {{ newsData.title }}
          </a>
          <button
            v-if="reportEnabled"
            class="lilo-button-grey is-size-7 ml-1"
            @click="
              $emit('openReportModal', newsData.providerName, newsData.title)
            "
          >
            <Icon name="mdi:flag-outline" />
          </button>
        </div>
        <div class="mt-1 is-size-7 is-font-family-secondary has-text-grey-dark">
          <p class="is-flex is-align-items-center">
            <span v-if="newsData.providerLogoUrl" class="mr-2">
              <figure class="image is-24x24">
                <NuxtImg
                  v-if="newsData.providerLogoUrl"
                  :src="newsData.providerLogoUrl"
                  :alt="newsData.providerName"
                  placeholder="/placeholder-1by1.svg"
                />
              </figure>
            </span>
            <span
              :v-if="newsData.providerName"
              class="is-capitalized mr-1 provider-name has-text-weight-semibold"
            >
              {{ newsData.providerName }}
            </span>
            <span v-if="device.isDesktop" class="has-text-grey has-dot-before">
              <span v-if="reportEnabled">{{
                getTimeSinceFromTimeStamp(
                  newsData.datePublished,
                  mainStore.locale,
                )
              }}</span>
              <span v-if="!reportEnabled">
                {{ getTimeSince(newsData.datePublished, mainStore.locale) }}
              </span>
            </span>
          </p>
          <p
            class="mt-1 is-size-6 is-size-7-touch is-font-family-secondary"
            :class="{
              'is-two-lines': !device.isDesktop,
              'is-three-lines': device.isDesktop,
            }"
          >
            <span v-if="!device.isDesktop" class="has-text-grey has-dot-after">
              <span v-if="reportEnabled">{{
                getTimeSinceFromTimeStamp(
                  newsData.datePublished,
                  mainStore.locale,
                )
              }}</span>
              <span v-if="!reportEnabled">
                {{ getTimeSince(newsData.datePublished, mainStore.locale) }}
              </span> </span
            >{{ newsData.description }}
          </p>
          <p
            v-if="newsData.paidDisclaimer"
            class="is-size-7 has-text-danger has-text-weight-medium"
          >
            {{ translate('home.news_feed.configure.item.is_paid') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { NewsResult } from '~/model/NewsResult'

export default defineComponent({
  name: 'NewsResult',

  props: {
    newsData: {
      type: Object as PropType<NewsResult>,
      default() {
        return {}
      },
    },
    reportEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  emits: ['openReportModal'],

  data() {
    return {
      config: useRuntimeConfig(),
    }
  },
})
</script>

<style scoped lang="scss">
@import '~/assets/scss/utils/breakpoints.scss';

figure {
  width: 100%;
  img {
    object-fit: cover;
  }
}

.has-dot-before:before,
.has-dot-after:after {
  content: ' · ';
}

@media screen and (max-width: $large - 1) {
  .provider-name {
    max-width: 7.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
