<template>
  <a :href="newsData.url" class="py-1 pl-0 pr-1">
    <!--    <hr class="my-0" />-->
    <div class="columns card px-2 is-vcentered">
      <div class="column px-1 is-9">
        <div class="is-flex mt-1 mb-1">
          <span v-if="newsData.providerLogoUrl" class="is-flex mr-2">
            <figure class="image is-24x24">
              <img
                :src="
                  device.isAndroid
                    ? proxyImage(newsData.providerLogoUrl)
                    : newsData.providerLogoUrl
                "
                :alt="newsData.providerName"
              />
            </figure>
          </span>
          <span
            :v-if="newsData.providerName"
            class="is-capitalized has-text-grey-dark is-size-7 pt-1 is-font-family-secondary"
          >
            {{ newsData.providerName }}
          </span>
        </div>
        <a
          :href="newsData.url"
          class="has-text-primary has-text-weight-medium is-three-lines is-size-6 pr-2"
        >
          {{ newsData.title }}
        </a>
        <p class="mt-3 has-text-grey is-size-7 is-font-family-secondary">
          {{ getTimeSince(newsData.datePublished, mainStore.locale) }}
        </p>
      </div>
      <div class="column is-3 pl-0">
        <figure class="image is-1remx1rem has-rounded-corners mt-4">
          <img
            v-if="newsData.thumbnailUrl"
            :src="
              device.isAndroid
                ? proxyImage(newsData.thumbnailUrl)
                : newsData.thumbnailUrl
            "
            :alt="newsData.title"
          />
          <img
            v-else
            src="@/assets/images/placeholder-1by1.svg"
            alt="1by1 icon"
          />
        </figure>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NewsItemMixin from './NewsItemMixin'

export default defineComponent({
  name: 'NewsGridItem',

  mixins: [NewsItemMixin],
})
</script>

<style scoped lang="scss">
.card {
  height: 9rem;
}
</style>
