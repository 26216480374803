export class NewsResult {
  datePublished: string
  imageUrl: string | null
  providerLogoUrl: string | null
  providerName: string | null
  title: string
  thumbnailUrl: string | null
  url: string
  description: string
  paidDisclaimer: boolean

  constructor(
    datePublished: string,
    imageUrl: string | null,
    providerLogoUrl: string | null,
    providerName: string | null,
    title: string,
    thumbnailUrl: string | null,
    url: string,
    description: string,
    paidDisclaimer: boolean = false,
  ) {
    this.datePublished = datePublished
    this.imageUrl = imageUrl
    this.providerLogoUrl = providerLogoUrl
    this.providerName = providerName
    this.title = title
    this.thumbnailUrl = thumbnailUrl
    this.url = url
    this.description = description
    this.paidDisclaimer = paidDisclaimer
  }

  toJSON() {
    return { ...this }
  }
}
