<template>
  <carousel :items-to-show="slidesPerPage" :items-to-scroll="slidesPerPage">
    <slot name="slides"></slot>
    <template #addons>
      <Navigation>
        <template #next
          ><img
            class="carousel__nav"
            src="@/assets/images/icons/arrow-right.svg"
            alt="right icon"
        /></template>
        <template #prev
          ><img
            class="carousel__nav"
            src="@/assets/images/icons/arrow-left.svg"
            alt="left icon"
        /></template>
      </Navigation>
    </template>
  </carousel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation } from 'vue3-carousel'

export default defineComponent({
  name: 'LiloCarousel',

  components: {
    Carousel,
    Navigation,
  },

  props: {
    slidesPerPage: {
      type: Number,
      default() {
        return null
      },
    },
  },
})
</script>

<style lang="scss">
@import '~/assets/scss/utils/variables.scss';

/*.carousel__viewport {
  mask-image: linear-gradient(
    90deg,
    transparent,
    black 1px,
    black calc(100% - 15px),
    transparent
  ) !important;
}*/

.carousel__slide {
  padding: 0 5px;
}

.carousel__track {
  display: flex;
  position: relative;
}

.carousel__next,
.carousel__prev {
  height: 40px;
  width: 40px;
  margin: 0;
}

.carousel__next {
  right: -16px;
}

.carousel__prev {
  left: -16px;
}

.carousel__nav {
  background-color: $light-blue;
  border: 2px solid #fff;
  padding-left: 0.55rem;
  padding-right: 0.55rem;
  border-radius: 8rem !important;
  height: 100%;
  width: 100%;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.25));

  &:hover {
    background-color: $light-blue-hovered;
    transition: background-color 500ms linear;
  }
}
</style>
