<template>
  <div class="card px-2 pt-2 mb-1">
    <a class="media" :href="videoData.contentUrl" :alt="videoData.name">
      <div class="media-left is-flex">
        <span class="tag is-dark is-flex duration">
          {{ readableDuration }}
        </span>
        <figure>
          <img :src="videoData.thumbnailUrl" :alt="videoData.name" />
        </figure>
      </div>
      <div class="media-content description">
        <div class="subtext mb-1 is-size-7">
          <p class="has-text-grey is-font-family-secondary">
            {{ getTimeSince(videoData.datePublished, mainStore.locale) }}
          </p>
        </div>
        <a class="has-text-primary has-text-weight-medium is-one-line">
          {{ videoData.name }}
        </a>
        <div class="mt-1 is-size-6">
          <p
            v-if="videoData.publisherName"
            class="has-text-grey-dark has-text-weight-medium"
          >
            {{ videoData.publisherName }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VideoItemMixin from './VideoItemMixin'

export default defineComponent({
  name: 'VideoGridItem',

  mixins: [VideoItemMixin],
})
</script>

<style scoped lang="scss">
img {
  object-fit: cover;
  width: 7.8rem;
  height: 4.4rem;
}

.has-text-primary {
  font-size: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.duration {
  position: absolute;
  align-self: flex-end;
  margin-bottom: 0.45rem;
  opacity: 0.85;
  background-color: hsl(0, 0%, 15%);
  font-weight: 700;
  border-top-right-radius: 0.75rem;
}
</style>
