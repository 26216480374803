<template>
  <div
    class="column is-two-fifth p-0"
    :class="{
      'pl-5': device.isDesktop,
    }"
  >
    <div class="card no-hover">
      <div class="card-header p-1">
        <div class="card-header-title">
          <figure
            v-if="imageResult.hostPageFavIconUrl"
            class="image is-inline-flex m-0 is-32x32 mr-3"
          >
            <img
              class="media-logo"
              :src="imageResult.hostPageFavIconUrl"
              :alt="imageResult.hostPageUrl"
            />
          </figure>
          {{ hostPageDisplayText }}
        </div>
        <button
          v-if="imageStore.previousImageIndex !== null"
          class="card-header-icon"
          aria-label="previous image"
          @click="previewPreviousImage"
        >
          <Icon name="mdi:chevron-left" />
        </button>
        <button
          v-if="imageStore.nextImageIndex !== null"
          class="card-header-icon"
          aria-label="next image"
          @click="previewNextImage"
        >
          <Icon name="mdi:chevron-right" />
        </button>
        <button
          class="card-header-icon"
          aria-label="more options"
          @click="clearPreview"
        >
          <Icon name="mdi:close" />
        </button>
      </div>
      <div class="card-image p-4">
        <figure>
          <img :src="imageResult.contentUrl" :alt="imageResult.name" />
        </figure>
        <span class="tag is-dark is-flex is-font-family-secondary">
          {{ imageResult.width }} x {{ imageResult.height }}
        </span>
      </div>
      <div class="card-content pt-2 is-font-family-secondary no-hover">
        <p class="has-text-weight-semibold">
          {{ imageResult.name }}
        </p>
        <div class="buttons mt-4 mb-3 has-text-weight-semibold">
          <a
            :href="imageResult.hostPageUrl"
            class="button is-primary is-rounded is-size-6 is-outlined"
            >{{ translate('image.preview.browse') }}</a
          >
          <a
            :href="imageResult.contentUrl"
            target="_blank"
            class="button is-primary is-rounded is-size-6 is-outlined"
            >{{ translate('image.preview.view') }}</a
          >
        </div>
        <p class="has-text-grey is-size-7">
          {{ translate('image.preview.disclaimer') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { ImageResult as ImageResultModel } from '~/model/ImageResult'

export default defineComponent({
  name: 'ImagePreview',

  props: {
    imageResult: {
      type: Object as PropType<ImageResultModel>,
      default() {
        return null
      },
    },
  },

  computed: {
    hostPageDisplayText(): string {
      if (this.imageResult.hostPageDisplayName) {
        return this.imageResult.hostPageDisplayName
      }

      let displayText: string | null = null

      try {
        const url = new URL(this.imageResult.hostPageUrl)
        displayText = url.hostname.replace('www.', '')
      } catch (e) {
        displayText = this.imageResult.hostPageUrl
      }

      return displayText
    },
  },

  methods: {
    clearPreview(): void {
      this.imageStore.setImageToPreview({
        image: null,
        index: null,
      })
    },
    previewPreviousImage(): void {
      this.imageStore.setImageToPreview({
        image:
          this.imageStore.imageResponse.images[
            this.imageStore.previousImageIndex
          ],
        index: this.imageStore.previousImageIndex,
      })
    },
    previewNextImage(): void {
      this.imageStore.setImageToPreview({
        image:
          this.imageStore.imageResponse.images[this.imageStore.nextImageIndex],
        index: this.imageStore.nextImageIndex,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';
.card {
  @media screen and (min-width: $large) {
    position: sticky;
    top: 0;
    border-radius: 1rem;
    .card-image {
      figure {
        align-content: space-around;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;

        img {
          max-height: 30rem;
        }
      }
    }
  }

  @media screen and (max-width: $large - 1) {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    z-index: 10;
  }
  .card-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.tag {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
</style>
