import { DeepLink } from '~/model/DeepLink'

export class TextResult {
  deepLinks: Array<DeepLink>
  isAd: Boolean
  name: string
  snippet: string
  url: string
  displayUrl: string
  thumbnailUrl: string | null
  adPingSuffix: string | null

  constructor(
    deepLinks: Array<DeepLink>,
    isAd: Boolean,
    name: string,
    snippet: string,
    url: string,
    displayUrl: string,
    thumbnailUrl: string | null,
    adPingSuffix: string | null = null,
  ) {
    this.deepLinks = deepLinks
    this.isAd = isAd
    this.name = name
    this.snippet = snippet
    this.url = url
    this.displayUrl = displayUrl
    this.thumbnailUrl = thumbnailUrl
    this.adPingSuffix = adPingSuffix
  }
}
