import { NewsResult } from '~/model/NewsResult'

export class NewsSearchResponse {
  newsResults: Array<NewsResult>

  constructor(newsResults: Array<NewsResult>) {
    this.newsResults = newsResults
  }

  toJSON() {
    return { ...this }
  }
}
