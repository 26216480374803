<template>
  <Modal
    :show="show"
    classes="lilo-modal modal-bookmark-add"
    mask-class="no-overlay"
  >
    <template #header>
      <h4
        class="is-size-4 has-text-weight-semibold has-text-primary has-text-centered"
      >
        {{ translate('modal.bookmark.add.title') }}
      </h4>
      <img
        class="close"
        src="@/assets/images/icons/cross-dark.svg"
        width="12"
        alt="plus"
        @click="emitCloseModal"
      />
    </template>
    <template #body>
      <form class="modal-bookmark-add__form" @submit="addBookmark">
        <div class="field">
          <label for="url" class="mb-1 has-text-weight-semibold">
            {{ translate('modal.bookmark.add.help') }}
          </label>
          <input
            ref="input"
            name="url"
            type="text"
            class="input modal-bookmark-add__input mt-1"
            placeholder="pagesjaunes.fr"
            autofocus
            @input="error = false"
          />
        </div>
        <p v-if="error" class="has-text-danger mt-2 has-text-weight-semibold">
          {{ translate('modal.bookmark.add.error') }}
        </p>
      </form>
    </template>
    <template #footer>
      <div class="is-flex is-justify-content-center">
        <button class="lilo-button-grey mr-2" @click="emitCloseModal">
          {{ translate('cta.cancel') }}
        </button>
        <button type="submit" class="lilo-button-primary" @click="addBookmark">
          <img
            v-if="loading"
            src="@/assets/images/loader-white.svg"
            width="32px"
            alt="Loading"
          />
          <span v-else>{{ translate('cta.save') }}</span>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { postBookmark } from '~/services/bookmarkClient'
const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['close-modal-bookmark-add', 'update-bookmarks'])

const error = ref(false)
const input = ref(null)
const loading = ref(false)

onUpdated(() => {
  if (props.show) {
    input?.value?.focus()
  }
})

async function addBookmark(event) {
  event.preventDefault()
  const url = input?.value?.value

  if (!url || !validateUrl(url)) {
    error.value = true
    return
  }

  loading.value = true
  await postBookmark(url)
  emit('close-modal-bookmark-add')
  emit('update-bookmarks')
  loading.value = false
}

function emitCloseModal(event) {
  if (event.x && event.y) {
    // real click will have coordinates
    emit('close-modal-bookmark-add')
  }
}

function validateUrl(str: string) {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  if (!regex.test(str)) {
    return false
  } else {
    return true
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/utils/variables.scss';
@import '~/assets/scss/utils/breakpoints.scss';

.modal-bookmark-add {
  &__input {
    border-radius: 8px;
  }
}
</style>
