<template>
  <div v-if="id === 'addBookmarkPlaceholder'" class="bookmark bookmark__add">
    <div
      class="bookmark__icon bookmark__icon__add"
      role="button"
      @click="openAddBookmarkModal"
    >
      <img
        src="@/assets/images/icons/plus.svg"
        alt="add bookmark icon"
        role="button"
        @click="openAddBookmarkModal"
      />
    </div>
    <div class="bookmark__title" @click="openAddBookmarkModal">
      {{ translate('cta.add') }}
    </div>

    <AddBookmarkModal
      :show="isModalBookmarkAddShown"
      @close-modal-bookmark-add="isModalBookmarkAddShown = false"
      @update-bookmarks="$emit('update-bookmarks')"
    />
  </div>

  <div v-else class="bookmark draggable">
    <img
      v-if="loading"
      src="@/assets/images/loader-white.svg"
      width="48px"
      alt="Loading"
    />
    <div v-else class="bookmark__content">
      <img
        class="bookmark__menu-icon"
        :class="'bookmark__menu-icon--' + id"
        src="@/assets/images/icons/three-dots-vertical.svg"
        alt="menu"
        @click="menuClick"
      />
      <a class="bookmark__icon" :href="url" target="_blank">
        <img :src="icon ? icon : '/placeholder-1by1.svg'" alt="icon" />
      </a>
      <a class="bookmark__title is-one-line has-text-grey-dark">
        {{ translate(title) || title }}
      </a>
    </div>

    <BookmarkMenu
      :show="isModalBookmarkMenuShown"
      :x="xCoord"
      :y="yCoord"
      :url="url"
      :bookmark-id="id"
      @close-modal-bookmark-menu="isModalBookmarkMenuShown = false"
      @delete-bookmark-loading-start="loading = true"
      @delete-bookmark-loading-end="loading = false"
      @update-bookmarks="$emit('update-bookmarks')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import AddBookmarkModal from './AddBookmarkModal.vue'
import BookmarkMenu from './BookmarkMenu.vue'

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
})

defineEmits(['update-bookmarks'])

const isModalBookmarkAddShown = ref(false)
const isModalBookmarkMenuShown = ref(false)
const xCoord = ref(0)
const yCoord = ref(0)
const loading = ref(false)

onMounted(() => {
  const body = document.querySelector('body')
  body?.addEventListener('click', clickOutsideModal)
})

onUnmounted(() => {
  const body = document.querySelector('body')
  body?.removeEventListener('click', clickOutsideModal)
})

const openAddBookmarkModal = () => {
  isModalBookmarkAddShown.value = true
}

function menuClick(event) {
  event.preventDefault()
  event.stopPropagation()
  if (event?.target?.classList?.contains(`bookmark__menu-icon--${props.id}`)) {
    const menuWidth = 240
    isModalBookmarkMenuShown.value = !isModalBookmarkMenuShown.value
    xCoord.value =
      window.innerWidth - event.clientX < menuWidth
        ? event.clientX - menuWidth
        : event.clientX
    yCoord.value = event.clientY
  }
}

function clickOutsideModal(event: {
  target: { parentNode: { classList: { contains: (arg0: string) => boolean } } }
}) {
  if (isModalBookmarkMenuShown.value) {
    let keepModalOpen = false
    let target = event?.target
    if (target) {
      while (target && target.id !== 'home-container') {
        if (target.classList?.contains('bookmark__menu-icon')) {
          // Stop further actions
          keepModalOpen = true
          break
        }

        target = target.parentNode
      }
    }

    isModalBookmarkMenuShown.value = keepModalOpen
  }

  if (isModalBookmarkAddShown.value) {
    let keepModalOpen = false
    let target = event?.target
    if (target) {
      while (target && target.id !== 'home-container') {
        if (
          target.classList?.contains('bookmark__icon__add') ||
          target.classList?.contains('modal-bookmark-add')
        ) {
          // Stop further actions
          keepModalOpen = true
          break
        }

        target = target.parentNode
      }
    }

    isModalBookmarkAddShown.value = keepModalOpen
  }
}
</script>
