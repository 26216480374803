<template>
  <div class="pagination mt-6">
    <a
      v-if="pagination.currentPageNumber > 1"
      :href="`${baseQuery}&page=${pagination.currentPageNumber - 1}`"
      class="lilo-button-dark-outline is-inline-block mr-3 mb-2"
    >
      <img
        class="mx-3"
        src="@/assets/images/icons/arrow-left.svg"
        alt="left icon"
      />
      {{ translate('pagination.previous') }}
    </a>
    <a
      v-for="(item, index) in pagination.pages"
      :key="'pagination-' + index"
      :href="`${baseQuery}&page=${item.pageNumber}`"
      class="lilo-button-dark-outline mr-3 mb-2"
      :class="{
        'has-background-light-blue':
          item.pageNumber === pagination.currentPageNumber,
      }"
    >
      {{ item.pageNumber }}
    </a>
    <a
      v-if="pagination.pages.length > 0"
      :href="`${baseQuery}&page=${pagination.currentPageNumber + 1}`"
      class="lilo-button-dark-outline is-inline-block mb-2"
    >
      <span>{{ translate('pagination.next') }}</span>
      <img
        class="mx-3"
        src="@/assets/images/icons/arrow-right.svg"
        alt="right icon"
      />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Pagination } from '../model/Pagination'

export default defineComponent({
  name: 'SearchPagination',

  props: {
    pagination: {
      type: Object as PropType<Pagination>,
      default() {
        return null
      },
    },
  },

  computed: {
    baseQuery(): string {
      let query = `?q=${this.queryStore.q}`

      if (this.queryStore.filterDate) {
        query += `&date=${this.queryStore.filterDate}`
      }

      if (this.queryStore.filterMarket) {
        query += `&market=${this.queryStore.filterMarket}`
      }

      return query
    },
  },
})
</script>

<style scoped lang="scss">
.lilo-button {
  padding-left: 0.9rem !important;
  padding-right: 0.9rem !important;
}
</style>
