import { default as indexd8pBIE9qzuMeta } from "/home/elbisna/search/source/pages/index.vue?macro=true";
import { default as no_45servicegXrKgLVJmlMeta } from "/home/elbisna/search/source/pages/no-service.vue?macro=true";
import { default as oupsekmmtxt8E1Meta } from "/home/elbisna/search/source/pages/oups.vue?macro=true";
export default [
  {
    name: indexd8pBIE9qzuMeta?.name ?? "index",
    path: indexd8pBIE9qzuMeta?.path ?? "/",
    meta: indexd8pBIE9qzuMeta || {},
    alias: indexd8pBIE9qzuMeta?.alias || [],
    redirect: indexd8pBIE9qzuMeta?.redirect || undefined,
    component: () => import("/home/elbisna/search/source/pages/index.vue").then(m => m.default || m)
  },
  {
    name: no_45servicegXrKgLVJmlMeta?.name ?? "no-service",
    path: no_45servicegXrKgLVJmlMeta?.path ?? "/no-service",
    meta: no_45servicegXrKgLVJmlMeta || {},
    alias: no_45servicegXrKgLVJmlMeta?.alias || [],
    redirect: no_45servicegXrKgLVJmlMeta?.redirect || undefined,
    component: () => import("/home/elbisna/search/source/pages/no-service.vue").then(m => m.default || m)
  },
  {
    name: oupsekmmtxt8E1Meta?.name ?? "oups",
    path: oupsekmmtxt8E1Meta?.path ?? "/oups",
    meta: oupsekmmtxt8E1Meta || {},
    alias: oupsekmmtxt8E1Meta?.alias || [],
    redirect: oupsekmmtxt8E1Meta?.redirect || undefined,
    component: () => import("/home/elbisna/search/source/pages/oups.vue").then(m => m.default || m)
  }
]